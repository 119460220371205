import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";


const SocialMediaRecap = () => {


  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full  p-6">
      <div className="flex flex-row justify-between items-start gap-4">
        <h1 className="text-[14px] sm:text-[20px] font-bold p-2">Social Media Recap</h1>
        <form className="flex justify-center items-center text-black bg-transparent">
          <select
            id="TotalCommandsIssued"
            name="TotalCommandsIssued"
            className="rounded-xl w-full border-none bg-transparent text-[14px]"
          >
            <option value="Weather update" className=" text-black text-[14px] font-normal">This Month</option>
            <option value="Daily News Digest" className=" text-black text-[14px] font-normal">This week</option>
            <option value="Search Assistant" className=" text-black text-[14px] font-normal">This Year</option>
          </select>
        </form>
      </div>

      <div className="mt-5 mb-5">

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                 Network
                </th>
                <th scope="col" className="px-6 py-3">
                Visitors
                </th>
                <th scope="col" className="px-6 py-3">
                Followers
                </th>
                <th scope="col" className="px-6 py-3">
                LIKES
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                <FaFacebook className="text-[20px] font-bold" /> Facebook
                </th>
                <td className="px-6 py-4">
                34677
                </td>
                <td className="px-6 py-4">
                658
                </td>
                <td className="px-6 py-4">
                554
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                <FaTwitter className="text-[20px] font-bold" /> Twitter
                </th>
                <td className="px-6 py-4">
                  3245
                </td>
                <td className="px-6 py-4">
                  2344
                </td>
                <td className="px-6 py-4">
                  1999
                </td>
              </tr>
              <tr className="bg-white dark:bg-gray-800">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                <FaLinkedinIn className="text-[20px] font-bold" /> Linkedin
                </th>
                <td className="px-6 py-4">
                  678
                </td>
                <td className="px-6 py-4">
                  345
                </td>
                <td className="px-6 py-4">
                  656
                </td>
              </tr>
              <tr className="bg-white dark:bg-gray-800">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                <FaInstagram className="text-[20px] font-bold"/> Instagram
                </th>
                <td className="px-6 py-4">
                  678
                </td>
                <td className="px-6 py-4">
                  345
                </td>
                <td className="px-6 py-4">
                  656
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default SocialMediaRecap;
