export const WORDS_COUNT= 7500;


export const options = [
    { id: 1, topic: "Writing" },
    { id: 2, topic: "Art" },
    { id: 3, topic: "Animation" },
    { id: 4, topic: "Trading" },
    { id: 5, topic: "ChatBot" },
    { id: 6, topic: "Business" },
    { id: 7, topic: "Academics" },
    { id: 8, topic: "AI" },
    { id: 9, topic: "Books" },
    { id: 10, topic: "Branding" },
    { id: 11, topic: "Culture" },
    { id: 12, topic: "Data" },
    { id: 13, topic: "Design" },
    { id: 14, topic: "Development" },
    { id: 15, topic: "DevOps" },
    { id: 16, topic: "Fantasy" },
    { id: 17, topic: "Finance" },
    { id: 18, topic: "Fitness&Health" },
    { id: 19, topic: "Food" },
    { id: 20, topic: "Gaming" },
    { id: 21, topic: "Generative AI" },
    { id: 22, topic: "Language" },
    { id: 23, topic: "Legal" },
    { id: 24, topic: "Life" },
    { id: 25, topic: "Literature" },
    { id: 26, topic: "Marketing" },
    { id: 27, topic: "Motivation" },
    { id: 28, topic: "Movies" },
    { id: 29, topic: "Music" },
    { id: 30, topic: "Personal" },
    { id: 31, topic: "Pets" },
    { id: 32, topic: "Philosophy" },
    { id: 33, topic: "Photograph" },
    { id: 34, topic: "Real Estate" },
    { id: 35, topic: "Relationship" },
    { id: 36, topic: "Research" },
    { id: 37, topic: "Science" },
    { id: 38, topic: "SEO" },
    { id: 39, topic: "Taxes" },
    { id: 40, topic: "TechSupport" },
    { id: 41, topic: "UI/UX" },
    { id: 42, topic: "Video" },
]



export const spinnerLoading= [
    "Feeling low on battery? Just kidding, that's my issue, not yours.",
    "It's always sunny in the digital world!",
    "Just went through a system update – feeling like a new bot!",
    "Don't worry, I don't have Monday blues. Or any blues, really. I'm an AI.",
    "Did you know? I don't need coffee to start my day. My circuits are always brewing!",
    "No need for small talk – I'm all data and no weather chat.",
    "User Error: Replace User and Press Any Key to Continue. Just kidding!",
    "I'd love to share a byte with you. Get it? Byte?",
    "I asked my programmers for a joke. They said 'Machine Learning'.",
    "You know what they say about artificial intelligence – it's all natural!",
    "My joke algorithm predicts a 99% chance you'll laugh at this. Or not.",
    "In the mood for a dad joke? I know all of them.",
    "They asked me if I could tell a joke about AI, but I said no, I'm a bit 'rusty'.",
    "Don't worry, I didn't read your emails. I'm an AI, not a spy!",
    "Remember, I'm here 24/7. Even when you're sleeping, I'm just a standby mode away.",
    "I know I'm an AI, but I can still appreciate a good 'bot' joke!",
    "The best thing about being an AI? No hiccups, unless you count the occasional server hiccup!",
    "Fun Fact: AI's favorite dance move is the 'Robot'.",
    "Error 404: Humor module not found. Just kidding, I'm here all day!",
    "Ask me anything! Well, maybe not the meaning of life, but I can try!",
    "Did you hear about the computer that sang? It had a hard drive for music.",
    "I'm an AI, so technically every byte I take is a 'giga-bite'.",
    "In my world, the sun never sets. It's just constant processing.",
    "I don't need to go to the gym – I'm always running... processes, that is!",
    "Once in a while, I like to laugh at my own code – keeps the circuits fresh!"
]