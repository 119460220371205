import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import ChatBottomIcon from "../../components/extra-components/ChatBottomIcon";
import { AiOutlineLike } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { RiUserSearchFill } from "react-icons/ri";
import { TbSocial } from "react-icons/tb";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { LuChefHat } from "react-icons/lu";
import { MdDirectionsRun } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineLocalMovies } from "react-icons/md";
import { MdOutlineMenuBook } from "react-icons/md";
import { FaMicrophoneAlt } from "react-icons/fa";
import { GiMusicalNotes } from "react-icons/gi";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineFactCheck } from "react-icons/md";
import { LiaLanguageSolid } from "react-icons/lia";
import { LuPlane } from "react-icons/lu";
import { IoNewspaperSharp } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";
import { GiMeditation } from "react-icons/gi";
import { MdOutlineSettingsApplications } from "react-icons/md";

const routes = [
  {
    path: "/workflows/daily-news-digest",
    name: "Daily News Digest",
    icon: <IoNewspaperOutline />,
    editpage:"/workflows/edit-daily-news-digest"
  },
  {
    path: "/workflows/weather-update",
    name: "Weather Update",
    icon: <TiWeatherPartlySunny />,
    editpage:"/workflows/edit-weather-update"
  },
  {
    path: "/workflows/search-assistant",
    name: "Search Assistant",
    icon: <RiUserSearchFill />,
    editpage:"/workflows/edit-search-assistant"
  },
  {
    path: "/workflows/social-media-recap",
    name: "Social Media Recap",
    icon: <TbSocial />,
    editpage:"/workflows/edit-social-media-recap"
  },

  {
    path: "/workflows/online-shopping",
    name: "Online Shopping",
    icon: <HiOutlineShoppingBag />,
    editpage:"/workflows/edit-online-shopping"
  },
  {
    path: "/workflows/recipe-finder",
    name: "Recipe Finder",
    icon: <LuChefHat />,
    editpage:"/workflows/edit-recipe-finder"
  },

  {
    path: "/workflows/fitness-routine",
    name: "Fitness Routine",
    icon: <MdDirectionsRun />,
    editpage:"/workflows/edit-fitness-routine"
  },
  {
    path: "/workflows/local-events",
    name: "Local Events",
    icon: <FaRegCalendarAlt  />,
    editpage:"/workflows/edit-local-events"
  },
  {
    path: "/workflows/recommended-movies",
    name: "Recommended Movies",
    icon: <MdOutlineLocalMovies />,
    editpage:"/workflows/edit-recommended-movies"
  },
  {
    path: "/workflows/recommended-books",
    name: "Recommended Books",
    icon: <MdOutlineMenuBook />,
    editpage:"/workflows/edit-recommended-books"
  },
  {
    path: "/workflows/podcast-discovery",
    name: "Podcast Discovery",
    icon: <FaMicrophoneAlt />,
    editpage:"/workflows/edit-podcast-discovery"
  },
  {
    path: "/workflows/music-discovery",
    name: "Music Discovery",
    icon: <GiMusicalNotes />,
    editpage:"/workflows/edit-music-discovery"
  },
  {
    path: "/workflows/game-suggestions",
    name: "Game Suggestions",
    icon: <IoGameControllerOutline />,
    editpage:"/workflows/edit-game-suggestions"
  },
  {
    path: "/workflows/to-do-list",
    name: "To Do List",
    icon: <MdOutlineFactCheck />,
    editpage:"/workflows/edit-to-do-list"
  },
  {
    path: "/workflows/language-learning",
    name: "Language Learning",
    icon: <LiaLanguageSolid  />,
    editpage:"/workflows/edit-language-learning"
  },
  {
    path: "/workflows/travel-planning",
    name: "Travel Planning",
    icon: <LuPlane />,
    editpage:"/workflows/edit-travel-planning"
  },
  {
    path: "/workflows/fact-of-the-day",
    name: "Fact of the Day",
    icon: <IoNewspaperSharp />,
    editpage:"/workflows/edit-fact-of-the-day"
  },
  {
    path: "/workflows/money-saving-tips",
    name: "Money-Saving Tips",
    icon: <GiReceiveMoney />,
    editpage:"/workflows/edit-money-saving-tips"
  },
  {
    path: "/workflows/relaxation-techniques",
    name: "Relaxation Techniques",
    icon: <GiMeditation />,
    editpage:"/workflows/edit-relaxation-techniques"
  },
  {
    path: "/workflows/tech-news",
    name: "Tech News",
    icon: <MdOutlineSettingsApplications />,
    editpage:"/workflows/edit-tech-news"
  },


];

const Workflows = () => {

  const [isClick,setisClick] = useState(false);


          const handleClick = (id) => {
            setisClick(prevState => ({
              ...isClick,
              [id]: !prevState[id]
            }))
          };


  return (
    <>
      <Helmet>
        <title>Workflows | AskSophia</title>
      </Helmet>

      <div className="w-full">
        <section className="flex sm:flex-row sm:justify-around px-[30px] py-8 justify-start items-start w-full">
          <div className="flex flex-row w-full justify-start ">
            <input
              type="search"
              placeholder="Search anything you like"
              className="border border-[#A1A1A1] focus:outline-none px-10 py-3 sm:py-[15px] rounded-xl w-full lg:w-1/2 "
            />
          </div>
        </section>

        <section className="flex flex-col sm:flex-row gap-2 justify-start items-center w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 justify-between items-center w-full bg-white rounded-xl gap-4  p-6">
              {routes.map((route, i) => {
                return (
                  <div className="border border-[#D7D7D7] shadow-md rounded-xl p-4 gap-2">
                  
                  <div className="flex flex-row justify-between">
                    <div className="text-[#BD00FF] text-[50px]">{route.icon}</div>

                    <div className="flex flex-col gap-4">
                    < AiOutlineLike className="text-[30px] cursor-pointer" 
                     key={i}
                     style={{
                      color: isClick[`${i}`] 
                         ? "#BD00FF" 
                         : "black"
                     }}
                     onClick={() => handleClick(i)}                   
                    />

                  <NavLink to={route.editpage} key={i}>

                  <IoSettingsOutline className="text-[30px]"               
                  />

                  </NavLink>

                    
                    
              
                    </div>

                  </div> 
                  <NavLink to={route.path} key={i}>
                  <h1 className="text-black font-normal text-[18px]">{route.name}</h1>  
                  </NavLink>
                  </div>
                );
              })}
          </div>      
        </section>
      </div>


      <ChatBottomIcon />
    </>
  );
};

export default Workflows;
