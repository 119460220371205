import React from "react";
import { Chart } from "primereact/chart";



const MostUsedWorkflows= () => {

    const data = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      
      datasets: [
          {
              data:[10,50,30,76,15,88,19],
              fill: true,
              borderWidth: 2,
              borderColor: '#8300D3',
              backgroundColor: '#F4DBFC',
              tension: 0.1,
          },
      ]
  };
  const options = {
      responsive: true,
      plugins: {
          title: {
              display: false,
          },
          legend: {
              display: false,
          },
          scales: {
              y: 
              {
                  type: 'string',
                  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                }
          }
      }
  };
  
  
    return (
      <div className="w-full p-6">
  
        <div className="mt-5 mb-5 border-[0px]">
          <p className="p-4"><Chart type="bar" data={data} options={options}/></p>
        
        </div>
      </div>
    );
  };

export default MostUsedWorkflows
