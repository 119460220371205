const ChatBodyBot = ({ chatItem }) => {

  return (

    <>

    <div className="chat chat-end rounded-[4px] px-4 py-2 mt-4 mx-4 flex-grow">
      <div className="flex justify-end mb-4 space-y-2 p-2 ">
        <div className="chat-bubble flex items-center max-w-5xl place-self-end text-right">
          <pre
            style={{ fontFamily: "Inter", whiteSpace: "pre-wrap" }}
            className="font-medium text-[15px] font-medium text-[15px] text-right p-2  px-6 mx-2 place-self-end bg-blue-400 text-white rounded-2xl ">{chatItem.content}
          </pre>
          <div className="chat-image avatar">
            <div className="w-10 h-10 rounded-full outline custom-outline">
              <img
                className="peer cursor-pointer w-8 h-8 rounded-full object-cover"
                src='/images/sophia.png' />
            </div>
          </div>

        </div>
      </div>
    </div>

    </>


  )
}

export default ChatBodyBot