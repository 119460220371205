import { UserContext } from "../App";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { BiLogoFacebook } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { register, signupGoogleUser } from "../api/userService";

import {
  //PersonOutlined,
  VisibilityOutlined,
  EmailOutlined,
  VisibilityOffOutlined,
  PersonOutlined,
} from "@mui/icons-material";
import { toast } from "react-toastify";

const SignUp = () => {
  const { state, dispatch } = useContext(UserContext);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";

  const [isAgreed, setIsAgreed] = useState(false);

  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isError) {
      toast.error(state.message);
    }
    if (state.isSuccess) {
      toast.success(state.message);
      navigate(from, { replace: true });
    }
    dispatch({ type: "RESET" });
  }, [
    state.isError,
    state.isSuccess,
    state.isLoading,
    state.message,
    navigate,
    dispatch,
  ]);

  const [formData, setFormData] = useState({
    /* username: "", */
    email: "",
    password: "",
    cpassword: "",
  });
  const { username, email, password, cpassword } = formData;
  // setForm data values
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Submit the htmlForm
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(password);
    console.log(email);
    if (username && password && email && cpassword) {
      console.log("i am under watersssssssss");
      const userData = { username,email, password };
      await register(userData, dispatch);
    } else {
     console.log("i am under water");
      toast.error("Fields should not be emptyyyyyyyyyyyyy", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => signupGoogleUser(response.credential, dispatch),
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        theme: "",
        shape: "",
        text: "",
      });
    }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>AskSophia | Sign UP</title>
      </Helmet>
      <div className="sm:overflow-hidden sm:h-screen">
        <div class=" flex flex-col lg:flex-row ">
          <div className="w-full flex items-center justify-center h-screen bg-white">
            <div className="rounded-t-lg py-2 w-full flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <img
                  src="/images/asksophia-logo.png"
                  alt="asksphia logo"
                  className="mb-1 w-[180px]"
                />
                <div className="py-2 text-center">
                  <h1 className="text-black text-[32px] font-bold py-1">
                    Welcome to AskSophia
                  </h1>
                  <p className="text-gray-700 text-[16px] font-normal py-1">
                    Your Lifestyle Manager & Digital BFF!
                  </p>
                </div>
              </div>

              <div className="loginformdiv items-center justify-center">
                <div className="py-2">
                  <h3 className=" text-black text-left text-[30px] leading-[40px] font-bold mb-1">
                    Sign up
                  </h3>
                </div>

                <form className="signUp" onSubmit={handleSubmit}>
                  <div className="mb-2 relative">
                    <label htmlFor="Email">
                      <h1 className="py-2 text-[16px]">Email</h1>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      className="text-[14px] h-[54px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Enter your email address"
                      required
                    />
                    <span className="absolute right-4 text-gray-400 justify-center items-center h-5 w-5">
                      <EmailOutlined />
                    </span>
                  </div>
                    <div className="mb-4 relative">
                    <label htmlFor="Email">
                      <h1 className="py-2  text-[16px]">Username</h1>
                    </label>
                    <input
                      type="text"
                      value={username}
                      onChange={handleChange}
                      id="username"
                      name="username"
                      className="text-[14px] h-[54px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Username"
                      required
                    />
                    <span className="absolute right-4 text-gray-400 justify-center items-center h-5 w-5">
                      <PersonOutlined />
                    </span>
                    </div> 

                  <div className="mb-2 relative">
                    <label htmlFor="Create a password">
                      <h1 className="py-2 text-[16px]">Create a password</h1>
                    </label>
                    <input
                      type={`${visible ? "text" : "password"}`}
                      value={password}
                      onChange={handleChange}
                      id="password"
                      name="password"
                      className="text-[14px] h-[54px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Create a secure password"
                      required
                    />
                    <span
                      onClick={() => setVisible(!visible)}
                      className="cursor-pointer absolute right-4 text-gray-400 justify-center items-center h-5 w-5"
                    >
                      {visible ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </span>
                  </div>
                  <div className="mb-2 relative">
                    <label htmlFor="Confirm password">
                      <h1 className="py-2 text-[16px]">Confirm password</h1>
                    </label>
                    <input
                      type={`${visible ? "text" : "password"}`}
                      value={cpassword}
                      onChange={handleChange}
                      id="cpassword"
                      name="cpassword"
                      className="text-[14px] h-[54px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Confirm your password"
                      required
                    />
                    <span
                      onClick={() => setVisible(!visible)}
                      className="cursor-pointer absolute right-4 text-gray-400 justify-center items-center h-5 w-5"
                    >
                      {visible ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </span>
                  </div>

                  <div className="flex justify-start items-start">
                    <div class="flex items-center justify-start mb-2">
                      <input
                        id="default-radio-1"
                        type="checkbox"
                        className="w-4 h-4 mt-10 mr-[10px] text-fuchsia-800 bg-gray-100 border-gray-100 rounded"
                        checked={isAgreed}
                        onChange={(e) => setIsAgreed(e.target.checked)}
                      />

                      <div className="flex flex-col">
                        <h1 className="text-black text-left font-bold text-[22px] py-2">
                          Unlock My Peace & Order
                        </h1>

                        <label
                          htmlFor="default-radio-1"
                          classname="ml-[8px] text-[15px] leading-[30px] font-normal text-black py-4"
                        >
                          Yes, I want personalized insights and workflow <br />
                          recommendations. (Optional)
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-[27px] mb-2">
                    <button className="w-full text-[18px] bg-[#3E7EFF] rounded-md px-4 py-2 text-white bg-button-custom ">
                      Sign Up
                    </button>
                  </div>
                </form>
                <div className="flex lg:hidden mt-[27px] ">
                  <Link
                    to="/login"
                    className="w-full text-[18px] text-center rounded-md px-4 py-2 text-white bg-button-custom"
                  >
                    Login
                  </Link>
                </div>
                <div className="relative my-4">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-[#E4E6E8]"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or Register with
                    </span>
                  </div>
                </div>
                <div className="flex flex-col mb-2 gap-2">
                  <div className="mt-2 flex flex-col justify-center space-x-4 space-x-reverse gap-4 lg:flex-row">
                    <div className="flex w-full items-center  justify-center custom-login-auth-button">
                      <button className=" flex justify-center items-center fa-2xl">
                        <BiLogoFacebook />
                      </button>
                    </div>
                    <div className="flex items-center w-full justify-center custom-login-auth-button">
                      <button
                        id="loginDiv"
                        className=" flex justify-center items-center fa-2xl"
                      >
                        <FcGoogle />
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" lg:flex justify-center items-center p-2 text-center md:hidden sm:hidden">
                  <h3 className="text-black font-normal text-[16px]">
                    Already have an account?
                    <Link to="/login">
                      <span className=" text-lg text-[#BD00FF] font-semibold">
                        {" "}
                        Log in{" "}
                      </span>
                    </Link>
                  </h3>
                </div>

                <div className=" lg:flex justify-center items-center p-2 text-center md:hidden sm:hidden">
                  <h3 className=" text-black font-normal text-[14px]">
                    By signing up, you agree to AskSophia's
                    <Link to="/login">
                      <span className=" text-sm text-[#BD00FF] text-[12px]">
                        {" "}
                        Terms of Service{" "}
                      </span>
                    </Link>
                    <span> and </span>
                    <Link to="/login">
                      <span className=" text-sm text-[#BD00FF] text-[12px] ">
                        {" "}
                        Privacy Policy{" "}
                      </span>
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:flex flex-col items-center justify-center bg-button-custom md:hidden sm:hidden">
            <div className="custom-login-box justify-center items-center">
              <h1 className="login-form-text">
                Join The
                <br />
                <span className=" text-black"> AskSophia</span>
                <br />
                Family
              </h1>
              <p className=" text-white login-form-text2 ">
                Your Digital BFF and Lifestyle
                <br />
                Manager is Just One Tap Away!
              </p>
            </div>

            <div className="custom-login-box-2 flex justify-start items-center gap-8">
              <div className="flex flex-row md:flex-col">
                <img src="/images/share_img.png" alt="Benefit_logo" />
              </div>

              <div className=" px-10">
                <ul className="text-left text-white login-list-text">
                  <li className=" list-disc py-2">
                    Extended Two-Week Free Trial
                  </li>
                  <li className=" list-disc py-2">
                    AI-Driven Lifestyle Manager
                  </li>
                  <li className=" list-disc py-2">
                    Trusted by Influencers and Experts
                  </li>
                  <li className=" list-disc py-2">
                    Intuitive Onboarding and User Experience
                  </li>
                  <li className=" list-disc py-2">
                    Continuous Personalization
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
