
import { useState } from "react";
import {  motion } from "framer-motion";
import { IoIosArrowDropright } from "react-icons/io";
import MetricSidebarTab from "../components/MetricSidebarTab";

 

const MetricSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={isOpen ? 'met_main_container_active shadow-lg border-[2px] ' : 'met_main_container_inactive shadow-lg border-[2px]'}>
        <motion.div
          animate={{
            width: isOpen ? "700px" : "60px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={isOpen ? 'sidebar_active' : 'sidebar_inactive'}
        >
          <div className="flex flex-col justify-between items-start gap-4 ">

            <div>
              <IoIosArrowDropright onClick={toggle} className="text-[30px] mt-4"/>
            </div>

            <div>
              <h1 className={isOpen ? 'metric-text_active' : 'metric-text_inactive'}> User Metrics Dashboard</h1>
            </div>  

          </div>

          <div>
          {isOpen ? <MetricSidebarTab /> : ''}              
          </div>   
          
        </motion.div>


        
      </div>
    </>
  );
};

export default MetricSidebar;
