export const json = {
  showPreviewBeforeComplete: "showAnsweredQuestions",
  showAnsweredQuestions:true,
  title: "Hey Troy",
  progressBarType: "pages",
  showProgressBar: "top",
  description:"Help us customize your experience by telling us a bit about yourself and your goals.",
  descriptionLocation: "underTitle",
  logo: "./images/hand.png",
  logoWidth: 30,
  logoHeight: 30,
  logoFit: "contain",
  logoPosition: "left",
  pagePrevText: "Back",
  // completeText: "Confirm",
  showQuestionNumbers: "false",
  showCompletedPage:false,
  // navigateToUrl:"/complete-survey",
  pages: [
    /* Privacy Settings */
    {
      name: "Privacy Settings",
      title: "Privacy Settings",
      description: "Enable personalized ads:",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Privacy Settings",
          elements: [
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "cookies",
              startWithNewLine: true,
              title: "Cookies",
              description:
                "Allow AskSophia to store cookies to remember your settings and preferences",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "searchHistory",
              startWithNewLine: true,
              title: "Search History",
              description:
                "Save your search queries to enhance the search algorithm and make recommendations.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "locationServices",
              startWithNewLine: true,
              title: "Location Services",
              description:
                "Allow AskSophia to access your location for more accurate weather, news, and local info.",
              descriptionLocation: "underTitle",
            },

            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "personalizedAds",
              startWithNewLine: true,
              title: "Personalized Ads",
              description:
                "Show personalized advertisements based on your browsing behavior.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "dataSharing",
              startWithNewLine: true,
              title: "Data Sharing",
              description:
                "Share your data anonymously for analytics and improvements.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "thirdPartyTracker",
              startWithNewLine: true,
              title: "Third-party Trackers",
              description:
                "Allow or disallow third-party websites from tracking your activity.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "cameraAndMicrophone",
              startWithNewLine: true,
              title: "Camera and Microphone",
              description:
                "Allow AskSophia to access your camera and microphone for video and voice interactions.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "notification",
              startWithNewLine: true,
              title: "Notifications",
              description:
                "Enable AskSophia to send you notifications for news updates, reminders, etc.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "autoLogin",
              startWithNewLine: true,
              title: "Automatically Log In",
              description:
                "Keep you logged in for quicker access to AskSophia.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "biometricVerify",
              startWithNewLine: true,
              title: "Biometric Verification",
              description:
                "Enable facial recognition or fingerprint scanning for extra security.",
              descriptionLocation: "underTitle",
            },
          ],
        },
      ],
      
    },
/* Customization Intro */
    {
      name: "Customization Intro",
      title: "Customization Intro",
      description: "Make AskSophia yours by customizing settings!",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Customization Intro",
          elements: [
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "darkMode",
              startWithNewLine: false,
              title: "Dark Mode",
              description:
                "Toggle between dark and light themes to reduce eye strain and save battery life.",
              descriptionLocation: "underTitle",
            },
                        
            {
              type: "text",
              name: "fontSize",
              startWithNewLine: true,
              title: "Font Size",
              inputType: "range",
              defaultValue: "14",
              min: 14,
              max: 52,
              step: 2,
              description:
                "Adjust the font size(px) for better readability.",
              descriptionLocation: "underTitle",
            },

            {
              type: "imagepicker",
              name: "layout",
              startWithNewLine: true,
              title: "Layout",
              description:
                "Choose between a grid or list layout for your news feed, bookmarks, etc.",
              descriptionLocation: "underTitle",
              choices: [
                {
                  value: "grid",
                  text: "Grid",
                  imageLink: "./images/grid.png",
                },
                {
                  value: "list",
                  text: "List",
                  imageLink: "./images/list.png",
                },
              ],
              colCount: 2,
              showLabel: true,
              multiSelect: false
            },

            {
              type: "dropdown",
              name: "notificationStyle",
              startWithNewLine: true,
              title: "Notification Style",
              description:
                "Choose how you’d like to receive notifications (Banner, Alert, None).",
              descriptionLocation: "underTitle",
              choices: [
                {
                  value: "banner",
                  text: "Banner",
                },
                {
                  value: "alert",
                  text: "Alert",
                },
                {
                  value: "none",
                  text: "None",
                },
              ],
            },           

            {
              type: "text",
              name: "colorScheme",
              startWithNewLine: true,
              title: "Color Scheme",
              inputType: "color",
              defaultValue: "#BD00FF",
              description:
                "Customize the primary and secondary colors of the interface.",
              descriptionLocation: "underTitle",
            }, 

            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "soundEffect",
              startWithNewLine: true,
              title: "Sound Effects",
              description:
                "Enable or disable sound effects for interactions like taps, notifications, etc.",
              descriptionLocation: "underTitle",
            },
            {
              type: "dropdown",
              name: "language",
              startWithNewLine: true,
              title: "Language",
              description:
                "Choose the language you’d like the interface to be displayed in",
              descriptionLocation: "underTitle",
              choices: [
                {
                  value: "english",
                  text: "English",
                },
                {
                  value: "italian",
                  text: "Italian",
                },
                {
                  value: "spanish",
                  text: "Spanish",
                },
                {
                  value: "portuguees",
                  text: "Portuguees",
                },
              ],
            },

            // {
            //   type: "boolean",
            //   labelTrue:"On",
            //   labelFalse:"Off",
            //   valueTrue: "On",
            //   valueFalse: "Off",
            //   name: "Notifications",
            //   startWithNewLine: true,
            //   title: "Notifications",
            //   description:
            //     "Enable AskSophia to send you notifications for news updates, reminders, etc.",
            //   descriptionLocation: "underTitle",
            // },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "textToSpeech",
              startWithNewLine: true,
              title: "Text-to-Speech",
              description: "Enable or disable the text-to-speech feature.",
              descriptionLocation: "underTitle",
            },
            {
              type: "boolean",
              name: "showPreviews",
              startWithNewLine: true,
              valueTrue: "true",
              valueFalse: "false",
              title: "Show Previews",
              description:
                "Decide if you want to see previews of news, emails, etc.",
              descriptionLocation: "underTitle",
            },

            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "accessibility",
              startWithNewLine: true,
              title: "Accessibility",
              description:
                "Enable additional features like screen reader, high contrast, etc.",
              descriptionLocation: "underTitle",
            },
          ],
        },
      ],
    },
/* Let’s personalize your experience */
    {
      name: "Let’s personalize your experience",
      title: "Let’s personalize your experience",
      description:
        "Customizing your settings helps tailor your AskSophia experience to your specific needs and preferences.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Let’s personalize your experience",
          descriptionLocation: "underTitle",      
              elements: [
                {
                  type: "image",
                  name: "surveyJS-integration-tutorial",
                  imageLink: "https://youtu.be/RAXo6pzOczQ",
                  imageHeight: 450,
                  imageWidth: 800,
                },
              ],
        },
      ],
    },
/* Fast-Track Your Setup with Everyday Browser Profiles */
    {
      name: "Fast-Track Your Setup with Everyday Browser Profiles",
      title: "Fast-Track Your Setup with Everyday Browser Profiles",
      description:
        "Quickly set up AskSophia to suit your browsing needs. Choose from our pre-configured everyday browser profiles.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Profile Choices",
          elements: [
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "newsAndResearch",
              startWithNewLine: false,
              title: "News & Research",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "socialMediaEntertainment",
              startWithNewLine: true,
              title: "Social Media & Entertainment",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "shoppingService",
              startWithNewLine: true,
              title: "Shopping & Services",
            },

          ],
          
        },
        {
          type: "panel",
          name: "Let’s personalize your experience",
          descriptionLocation: "underTitle",      
              elements: [
                {
                  type: "image",
                  name: "surveyJS-integration-tutorial",
                  imageLink: "https://youtu.be/RAXo6pzOczQ",
                  imageHeight: 450,
                  imageWidth: 800,
                },
              ],
        },
      ],
    },

    {
      name: "Customize Your News Feed",
      title: "Customize Your News Feed",
      description:
        "Select the types of news you’re interested in to tailor your daily news feed.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "News Feed Choices",
          elements: [
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "worldFeeds",
              startWithNewLine: false,
              title: "World News",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "tech",
              startWithNewLine: true,
              title: "Tech",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "entertainment",
              startWithNewLine: true,
              title: "Entertainment",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "sports",
              startWithNewLine: true,
              title: "Sports",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "science",
              startWithNewLine: true,
              title: "Science",
            },
            {
              type: "boolean",
              labelTrue:"On",
              labelFalse:"Off",
              valueTrue: "true",
              valueFalse: "false",
              name: "health",
              startWithNewLine: true,
              title: "Health",
            },

          ],
          
        },
        {
          type: "panel",
          name: "Let’s personalize your experience",
          descriptionLocation: "underTitle",      
              elements: [
                {
                  type: "image",
                  name: "surveyJS-integration-tutorial",
                  imageLink: "https://youtu.be/RAXo6pzOczQ",
                  imageHeight: 450,
                  imageWidth: 800,
                },
              ],
        },
      ],
    },
/* Learn About Bookmarks */
    {
      name: "Learn About Bookmarks",
      title: "Learn About Bookmarks",
      description:
        "Discover how to bookmark your favorite sites, articles, and more.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Learn About Bookmarks",
          descriptionLocation: "underTitle",      
              elements: [
                {
                  type: "image",
                  name: "surveyJS-integration-tutorial",
                  imageLink: "https://youtu.be/RAXo6pzOczQ",
                  imageHeight: 450,
                  imageWidth: 800,
                },
              ],
        },
      ],
    },
/* Learn About Extensions  */
    {
      name: "Learn About Extensions",
      title: "Learn About Extensions",
      description:
        "Discover how to enhance your browsing experience with AskSophia extensions.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          name: "Learn About Extensions",
          descriptionLocation: "underTitle",      
              elements: [
                {
                  type: "image",
                  name: "surveyJS-integration-tutorial",
                  imageLink: "https://youtu.be/RAXo6pzOczQ",
                  imageHeight: 450,
                  imageWidth: 800,
                },
              ],
        },
      ],
    },
    /* Choose Immediate Use Cases  */
    {
      name: "Choose Immediate Use Cases",
      title: "Choose Immediate Use Cases",
      description:"Select the tasks you wish to accomplish with AskSophia immediately.",
      descriptionLocation: "underTitle",
      elements: [
        {
          type: "panel",
          elements: [
            {
              type: "checkbox",
              name: "category",
              title: "Workflow Categories",
              colCount: 3,
              choices: [ 
                "Daily News Digest", 
                "Weather Update", 
                "Search Assistant", 
                "Social Media Recap",
                "Online Shopping", 
                "Recipe Finder", 
                "Fitness Routine", 
                "Local Events",
                "Movie Recommendations", 
                "Book Recommendations", 
                "Podcast Discovery", 
                "Music Discovery", 
                "Game Suggestions", 
                "To-Do List",
                "Language Learning",
                "Travel Planner", 
                "Fact of the Day", 
                "Money-Saving Tips", 
                "Relaxation Guide",
                "Tech News", 
              ],
              "showSelectAllItem": false,
            },
          ]
        },
        {
          type: "panel",
          title: "Use Cases",
          name: "Use Cases",
          description:"Set your use cases",
          descriptionLocation: "underTitle",
          elements: [
            
            {
              type: "checkbox",
              name: "Daily News Digest",
              title: "Daily News",
              visibleIf: '{category} contains "Daily News Digest"',
              choices: [ 
                "Morning Briefing", 
                "Lunch Break Update", 
                "Evening Recap", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Weather Update",
              title: "Weather Update",
              visibleIf: '{category} contains "Weather Update"',
              choices: [ 
                "Start of Day Forecast", 
                "Pre-Commute Check", 
                "Travel Planning Weather", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Search Assistant",
              title: "Search Assistant",
              visibleIf: '{category} contains "Search Assistant"',
              choices: [ 
                "Quick Fact Check", 
                "Deep Dive Research", 
                "Shopping Comparison", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Social Media Recap",
              title: "Social Media Recap",
              visibleIf: '{category} contains "Social Media Recap"',
              choices: [ 
                "Morning Social Summary", 
                "Midday Check-In", 
                "Evening Wrap-Up", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Online Shopping",
              title: "Online Shopping",
              visibleIf: '{category} contains "Online Shopping"',
              choices: [ 
                "Daily Deals Alert", 
                "Price Drop Notifications", 
                "New Arrivals Showcase", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Recipe Finder",
              title: "Recipe Finder",
              visibleIf: '{category} contains "Recipe Finder"',
              choices: [ 
                "Meal Prep Ideas", 
                "Quick Dinner Recipes", 
                "Weekend Cooking Inspiration", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Fitness Routine",
              title: "Fitness Routine",
              visibleIf: '{category} contains "Fitness Routine"',
              choices: [ 
                "Morning Workout Kickstart", 
                "Midday Movement Break", 
                "Evening Relaxation Yoga", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Local Events",
              title: "Local Events",
              visibleIf: '{category} contains "Local Events"',
              choices: [ 
                "Upcoming Weekend Events", 
                "Cultural Happenings This Month", 
                "Nightlife Spotter", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Movie Recommendations",
              title: "Movie Recommendations",
              visibleIf: '{category} contains "Movie Recommendations"',
              choices: [ 
                "Movie Night Selections", 
                "New Releases Highlight", 
                "Critically Acclaimed Picks", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Book Recommendations",
              title: "Book Recommendations",
              visibleIf: '{category} contains "Book Recommendations"',
              choices: [ 
                "Weekly Bestsellers", 
                "Monthly Book Club Picks", 
                "Personalized Genre Suggestions", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Podcast Discovery",
              title: "Podcast Discovery",
              visibleIf: '{category} contains "Podcast Discovery"',
              choices: [ 
                "Commute Companion", 
                "Workday Podcasts", 
                "Evening Wind-Down", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Music Discovery",
              title: "Music Discovery",
              visibleIf: '{category} contains "Music Discovery"',
              choices: [ 
                "Morning Motivation Mix", 
                "Work Focus Tunes", 
                "Dinner Party Playlist", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Game Suggestions",
              title: "Game Suggestions",
              visibleIf: '{category} contains "Game Suggestions"',
              choices: [ 
                "Casual Gaming Break", 
                "Latest Game Drops", 
                "Multiplayer Match-ups", 
              ],
              "showSelectAllItem": true,
            },
            {
              type: "checkbox",
              name: "To-Do List",
              title: "To-Do List",
              visibleIf: '{category} contains "To-Do List"',
              choices: [ 
                "Daily Task Organizer", 
                "Weekly Planning Assistant", 
                "Project Milestone Tracker", 
              ],
              "showSelectAllItem": true,
            },
            {
              type: "checkbox",
              name: "Language Learning",
              title: "Language Learning",
              visibleIf: '{category} contains "Language Learning"',
              choices: [ 
                "Daily Language Nugget", 
                "Weekly Conversation Challenge", 
                "Cultural Immersion Tip", 
              ],
              "showSelectAllItem": true,
            },
            {
              type: "checkbox",
              name: "Travel Planner",
              title: "Travel Planner",
              visibleIf: '{category} contains "Travel Planner"',
              choices: [ 
                "Weekend Getaway Ideas", 
                "Upcoming Trip Checklist", 
                "Seasonal Travel Spots", 
              ],
              "showSelectAllItem": true,
            },
            {
              type: "checkbox",
              name: "Fact of the Day",
              title: "Fact of the Day",
              visibleIf: '{category} contains "Fact of the Day"',
              choices: [ 
                "Daily Trivia Teaser", 
                "Historical Fact of the Day", 
                "Science Factoid", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Money-Saving Tips",
              title: "Money-Saving Tips",
              visibleIf: '{category} contains "Money-Saving Tips"',
              choices: [ 
                "Daily Budget Tip", 
                "Weekly Finance Checkup", 
                "Monthly Savings Goal Tracker", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Relaxation Guide",
              title: "Relaxation Guide",
              visibleIf: '{category} contains "Relaxation Guide"',
              choices: [ 
                "Morning Mindfulness Moment", 
                "Afternoon Breather", 
                "Bedtime Unwind", 
              ],
              "showSelectAllItem": true,
            },

            {
              type: "checkbox",
              name: "Tech News",
              title: "Tech News",
              visibleIf: '{category} contains "Tech News"',
              choices: [ 
                "Daily Tech Brief", 
                "Weekly Innovation Roundup", 
                "Monthly Tech Deep Dive", 
              ],
              "showSelectAllItem": true,
            },
          ]
        },
      ],
    },
       /* Import Your Data  */
    {
        name: "Import Your Data",
        title: "Import Your Data",
        description:"Easily bring your bookmarks, passwords, and more to AskSophia.",
        descriptionLocation: "underTitle",
        elements: [
          {
            type: "panel",
            elements: [
              {
                type: "checkbox",
                name: "imports",
                title: "What are you importing?",
                choices: [ 
                  "Bookmarks", 
                  "Passwords", 
                  "SearchHistory", 
                  "Extensions",
                ],
                "showSelectAllItem": true,
              },
              {
                type: "checkbox",
                name: "sourceImports",
                title: "What is the source for import?",
                choices: [ 
                  
                  "Google Chrome", 
                  "Mozilla Firefox", 
                  "Safari", 
                  "Edge",
                ],
                "showSelectAllItem": true,
              },
              {
                type: "file",
                title: "Please upload your files",
                name: "files",
                storeDataAsText: true,
                waitForUpload: true,
                allowMultiple: false,
                maxSize: 102400,
                hideNumber: true
              },
            ]
          },
        ],       
    },
  ],

};

