import React from "react";
import { useNavigate } from "react-router-dom";
import { BiErrorCircle} from "react-icons/bi";
import Background from '../assets/images/final_bg.png';

const ThankyouSurvey = () => {
  const navigate = useNavigate();

  const navigateToOnboradingSurvey = () => {
    // 👇️ navigate to /survey
    navigate("/chat");
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen overflow-hidden"
    style={{
      backgroundImage: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    
    }}
    >
      <div className=" flex justify-center items-center p-1 ">
        <img
          src="./images/asksophia-logo.png"
          alt="logo"
          className="h-full w-full rounded-lg"
        />
      </div>
      <div className="flex flex-col justify-center items-center w-1/3 h-[10px] m-8 rounded-full bg-[#A900F0]"></div>
      <div className="flex justify-center items-center">
        <h1 className="font-sans text-[26px] leading-8 font-extrabold p-4">
          You’re All Set!
        </h1>
      </div>

      <div className="flex justify-center items-center">
        <h2 className="font-sans text-[20px] text-center sm:text-left leading-8 font-light p-4  ">
          Thank you for setting up AskSophia. We’re excited to have you on
          board.
        </h2>
      </div>
      <div className="flex justify-center items-center p-1 ">
        <img
          src="./images/thankyoupic.png"
          alt="Thankyou_logo"
          className=" h-[160px] w-[160px] sm:h-full sm:w-full"
        />
      </div>


      <div className="flex flex-col justify-center items-center">
        <button
          className="font-sans border bg-gradient-to-b from-[#BD00FF] to-[#8300D3] rounded-lg  px-4 py-3 w-auto m-2 text-white hover:-translate-x-1 hover:scale-105 duration-300 sm:px-12 sm:py-3"
          onClick={navigateToOnboradingSurvey}
        >
          Start Exploring
        </button>
      </div>

      <div className="flex justify-center items-center sm:mt-[80px]">
        <BiErrorCircle className=" text-[#858585] text-[30px] ml-[20px] cursor-pointer" />
        <span className="font-sans text-[14px] text-[#858585] leading-8 font-extralight p-1 mr-[20px] ">
          Tap to open a tooltip or redirect to a support page
        </span>
      </div>
      <div className="flex justify-center items-center ">
        <span className="font-sans text-[16px] leading-5 font-extralight p-1 ">
        *Revisit Onboarding Anytime in Settings
        </span>
      </div>
    </div>
  );
};

export default ThankyouSurvey;
