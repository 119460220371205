import React from 'react';
import { FaStar } from "react-icons/fa";
import { BsCalendar3EventFill } from "react-icons/bs";
import WeatherBox from './WeatherBox';
import EventsBox from './EventsBox';




const TopComponents = () => {
  return (

    <div className="rounded-xl border border-[#f5f5f5] w-full p-2 sm:p-6">

      <div className="flex flex-col  md:flex-row justify-between items-center w-full bg-white rounded-xl gap-4 p-2 sm:p-6">
       <WeatherBox />
       <EventsBox />
      </div >

    </div>

  )
}

export default TopComponents

