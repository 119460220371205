import { useContext, useState } from "react";
import Header from "./Header";
import MobileNav from "./MobileNav";
import { Logout } from "../../../api/userService";
import { UserContext } from "../../../App";
import { Navigate, useNavigate } from "react-router-dom";
import SideBar from "../../../dashboard-components/sidebar/SideBar";
import MetricSidebar from "../../../dashboard-components/sidebar/MetricSidebar";


const Layout = ({ children }) => {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState();
  const [open, setOpen] = useState(false);

  const onLogout = async () => {
    await Logout(state, dispatch);
    navigate("/login");
  };

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="flex w-full">
        <SideBar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <div className="lg:flex flex-col w-full h-full overflow-hidden">
          <Header onLogout={onLogout} />
          {children}
        </div>
        <MetricSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      </div>
    </>
  );
};
export default Layout;
