import React from 'react';
import { SlRefresh } from "react-icons/sl";
import { NavLink } from 'react-router-dom';


const editorpost = [
    {
      path: "/details",
      title: "Breakthrough in Renewable Energy",
      image: "../images/news1.png",
      date: "Dec 12, 2023",
      des: "Scientists unveil a revolutionary technology that promises to reshape the renewable energy landscape. The breakthrough could significantly...",
    },
  
    {
      path: "/details",
      title: "Breakthrough in Renewable Energy",
      image: "../images/news2.png",
      date: "Dec 12, 2023",
      des: "Scientists unveil a revolutionary technology that promises to reshape the renewable energy landscape. The breakthrough could significantly...",
    },

    {
        path: "/details",
        title: "Breakthrough in Renewable Energy",
        image: "../images/news1.png",
        date: "Dec 12, 2023",
        des: "Scientists unveil a revolutionary technology that promises to reshape the renewable energy landscape. The breakthrough could significantly...",
      },
  ]

const Editorpicks = () => {
  return (
    
    <div className='bg-[#F1DBFF] rounded-lg border w-full p-6'>

    <div className='flex flex-row justify-between items-center p-6'>
      <h1 className='text-[16px] sm:text-[24px] leading-6 text-black font-bold items-start justify-start'>Editor’s Pick</h1>
      <div className='flex flex-row gap-4 items-center p-4 '>
        <SlRefresh className=' cursor-pointer text-[#292929] text-[20px] font-extrabold' />
        <label className="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
          </div>
        </label>
      </div>
    </div>


    {editorpost.map((data, i) => {
      return (

        <>
          <div className='flex flex-row justify-between items-start gap-4'>

            <div>
              <img src={data.image} alt='image' className='rounded-full h-16 w-16 items-center justify-center' />
            </div>

            <div className='flex flex-col justify-evenly items-start gap-2 w-full'>

              <div className='flex flex-row justify-between items-center gap-4 w-full'>

                <h1 className='text-black font-bold text-[16px] leading-6 justify-start items-start '>{data.title}</h1>

                <span className='font-light text-[14px] text-[#515151] justify-center items-center'>{data.date}</span>

              </div>

              <div>
                <p className='text-black font-light text-[14px] leading-6 w-full'>
                  {data.des}
                  <span className='font-bold text-[16px] text-[#BD00FF] cursor-pointer'> <NavLink to={data.path} key={i}> see more</NavLink> </span>
                </p>
              </div>

            </div>
          </div>

          <hr className="h-px my-8 bg-gray-400 border-0 dark:bg-gray-800"></hr>

        </>

      );
    })}

  </div>
  )
}

export default Editorpicks