import { useContext } from "react";
import { UserContext } from "../../../../App";
import moment from "moment";

const ChatBodyUser = ({ chatItem }) => {
  const { state } = useContext(UserContext);
  const { user } = state;

  return (

    <>

      <div className="chat chat-start border px-4 py-2 mt-4 mx-4 flex-grow">
        <div className="flex space-y-2 p-2 justify-start mb-4 ">
          <div className="chat-bubble flex items-left max-w-5xl place-self-start text-left ">
            <div className="chat-image avatar">
              <div className="w-10 h-10 rounded-full outline custom-outline">
                <img
                  className="chat-image avatar peer cursor-pointer w-10 h-10 rounded-full object-cover"
                  src={`${user.img_url ? user.img_url : '/images/avatar.png'}`} />
              </div>
            </div>

            <p className="font-medium text-[15px] p-2 px-6 mx-2 self-start bg-indigo-800 text-white border-gray-100 rounded-[20px]">{chatItem.content}</p>
          </div>
        </div>
      </div>

    </>
  )
}

export default ChatBodyUser