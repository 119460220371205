import React from 'react';
import { Helmet } from 'react-helmet-async';
import SortFilter from './SortFilter';
import Category from './Category';
import Editorpicks from './Editorpicks';
import NewsCards from './NewsCards';
import { IoChevronBackSharp } from "react-icons/io5";
import { NavLink } from 'react-router-dom';




const DailyNewsDigest = () => {
  return (
    <>
      <Helmet>
        <title>Daily News Digest | AskSophia</title>
      </Helmet>

      <section className='flex flex-row w-full p-6 justify-start items-center gap-8'>
        <div className=' shadow-lg border-2 border-[#D8DADC] p-2 rounded-sm cursor-pointer' >
          <NavLink to="/workflows">

            <IoChevronBackSharp />

          </NavLink>

        </div>
        <h1 className='text-black text-[22px] sm:text-[32px] leading-[39px] font-bold'>Daily News Digest</h1>
      </section>

      <section className="flex-col flex sm:flex-row sm:justify-around px-[30px] py-8 justify-center items-center gap-4 sm:gap-20 md:gap-20 lg:gap-40 ">
        <div className="flex flex-row w-full justify-center lg:w-1/2">
          <input
            type="search"
            placeholder="Search anything you like"
            className="border border-[#A1A1A1] focus:outline-none px-10 py-3 sm:py-[15px] rounded-xl w-full xl:w-1/2 "
          />
        </div>
        <div className="flex flex-row justify-center items-center w-full  lg:w-1/2">
          <SortFilter />
        </div>
      </section>



      <section className='flex flex-col sm:flex-row gap-10 justify-evenly place-content-center  p-2 sm:p-6'>

        <Editorpicks />

        <Category />

      </section>

      <section className='flex flex-wrap justify-start place-content-center w-full p-2'>

        <div>
          <h1 className='font-bold text-[32px] text-black pl-4'>Your News Cards</h1>
        </div>

        <NewsCards />

      </section>


    </>
  )
}

export default DailyNewsDigest