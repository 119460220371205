import React from 'react'

const WeatherBox = () => {
    return (
        <div className="rounded-xl border border-[#D7D7D7] w-full p-6 bg-gradient-to-b from-yellow-200 via-yellow-100 to-indigo-300 gap-4 justify-center items-center h-auto">
           
            <div className='flex flex-row justify-center items-center gap-4'>
                <div className='p-2'>
                    <h1 className="text-gray-800 text-base font-medium font-['Inter'] tracking-wide">California</h1>
                </div>

            </div>
            <div className='flex flex-col  sm:flex-row justify-center items-center gap-2 p-1'>

                <h1 className='text-black text-[40px] sm:text-[60px] font-normal md:border-r-2 border-[#313030] sm:pr-2 '>13°</h1> 
                
                <div className='p-2'>
                    <h1 className="text-gray-800 text-[16px] sm:text-[20px] font-semibold font-['Inter']">Tuesday</h1>
                </div>
            </div>

            <div className='flex justify-center items-center p-1 '>
            <img src="../images/rainicon.png" alt="Weather image" />
            </div>

            <div className='flex flex-col justify-center items-center gap-2 p-1'>
                <h1 className=" text-gray-800 text-[14px]  sm:text-[16px] font-medium font-['Inter'] tracking-wide">Rainy Sunny</h1>
                <h1 className=" text-center text-gray-800 text-[14px] sm:text-[16px] font-medium font-['Inter']">H:21° L:9°</h1>
            </div>



        </div>
    )
}

export default WeatherBox