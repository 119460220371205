import React from 'react';
import { NavLink } from 'react-router-dom';


const newscard = [
    {
        path: "/details",
        title: "Global health initiative target infectious Disesses",
        image: "../images/newscard1.png",
        time: "2 hour ago",
        source: "Google News",
        des: "In a groundbreaking development, scientists have unveiled a revolutionary technology that has the potential to reshape the landscape of renewable energy. This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean energy sources, marks a crucial milestone in the ongoing efforts to address climate change and transition to a sustainable future,The new technology, developed through collaborative research efforts This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean",
    },
    {
        path: "/details",
        title: "Global health initiative target infectious Disesses",
        image: "../images/newscard2.png",
        time: "2 hour ago",
        source: "Google News",
        des: "In a groundbreaking development, scientists have unveiled a revolutionary technology that has the potential to reshape the landscape of renewable energy. This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean energy sources, marks a crucial milestone in the ongoing efforts to address climate change and transition to a sustainable future,The new technology, developed through collaborative research efforts This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean",
    },

    {
        path: "/details",
        title: "Global health initiative target infectious Disesses",
        image: "../images/newscard1.png",
        time: "2 hour ago",
        source: "Google News",
        des: "In a groundbreaking development, scientists have unveiled a revolutionary technology that has the potential to reshape the landscape of renewable energy. This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean energy sources, marks a crucial milestone in the ongoing efforts to address climate change and transition to a sustainable future,The new technology, developed through collaborative research efforts This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean",
    },

    {
        path: "/details",
        title: "Global health initiative target infectious Disesses",
        image: "../images/newscard2.png",
        time: "2 hour ago",
        source: "Google News",
        des: "In a groundbreaking development, scientists have unveiled a revolutionary technology that has the potential to reshape the landscape of renewable energy. This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean energy sources, marks a crucial milestone in the ongoing efforts to address climate change and transition to a sustainable future,The new technology, developed through collaborative research efforts This innovation, poised to significantly increase efficiency and reduce costs in harnessing clean",
    },
]

const NewsCards = () => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2  justify-between items-center w-full bg-white rounded-xl gap-4 p-2 sm:p-6">
            {newscard.map((data, i) => {
                return (

                    <>
                        <div className='flex flex-col sm:flex-row justify-between items-start gap-4 rounded-lg shadow-xl border-2 p-4'>


                            <div className='flex flex-col  justify-start items-start'>
                                <img src={data.image} alt='image' className=' h-40 w-full items-center justify-start' />
                                <div className='flex flex-row justify-between items-center gap-4 w-full'>

                                    <h1 className='text-black font-bold text-[16px] leading-6 justify-start items-start p-2'>{data.title}</h1>                                   

                                </div>
                                <span className='font-extralight text-[14px] text-[#515151] justify-center items-center pt-1 pl-2'>{data.time}</span>
                            </div>

                            <div className='flex flex-col justify-evenly items-start gap-2 w-full'>

                                <div>
                                    <p className='text-black font-light text-[14px] leading-6 w-full'>
                                        {data.des}
                                        <span className='font-bold text-[16px] text-[#BD00FF] cursor-pointer'> <NavLink to={data.path} key={i}> see more</NavLink> </span>
                                    </p>
                                </div>

                            </div>


                        </div>


                    </>

                );
            })}
        </div>
    )
}

export default NewsCards