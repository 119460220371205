import React from "react";
import { EmailOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../App";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);

  const from = location.state?.from?.pathname || "/chat";

  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  useEffect(() => {
    if (state.isError) {
      toast.error(state.message);
    }
    if (state.user || state.isSuccess) {
      navigate(from, { replace: true });
    }

    dispatch({ type: "RESET" });
  }, [
    state.isError,
    state.isLoading,
    state.isSuccess,
    state.user,
    state.message,
    navigate,
    dispatch,
  ]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <>
      <Helmet>
        <title>AskSophia | Forgot Password</title>
      </Helmet>
      <div className="overflow-hidden h-screen">
        <div class=" flex flex-col lg:flex-row ">
          <div className="w-full flex items-center justify-center h-screen bg-white">
            <div className="rounded-t-lg py-8 w-full flex flex-col justify-center items-center">
              <div className="flex flex-col justify-start items-start text-left">
                <div className="flex justify-start items-center p-4">
                  <img
                    src="/images/asksophia-logo.png"
                    alt="asksphia logo"
                    className="mb-4 w-[180px]"
                  />
                </div>
              </div>
              <div className=" p-4">
                <div className="flex justify-start items-start">
                  <h3 className="text-black text-[32px] leading-[41px] font-bold  p-1">
                    Forgot Password
                  </h3>
                </div>
                <div className="text-gray-600 text-[20px] leading-[40px] font-thin  p-1">
                  <p>
                    Don’t worry! It happens. Please enter the email<br/> associated
                    with your account.
                  </p>
                </div>
              </div>

              <div className="w-[420px] items-center justify-center p-2">
                <form className="w-[420px]" onSubmit={handleSubmit}>
                  <div className="mb-4 relative">
                    <label htmlFor="email">
                      <h1 className="py-3">Email</h1>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      className="text-[14px] w-[420px] h-[55px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0"
                      placeholder="example@gmail.com"
                      required
                    />
                    <span className="absolute right-4 text-gray-400 justify-center items-center  h-5 w-5">
                      <EmailOutlined />
                    </span>
                  </div>

                  <div className="flex mt-[27px] ">
                    <button
                      className="w-full text-xl bg-button-custom rounded-md px-4 py-3 text-white"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>

          <div className="w-full lg:flex flex-col items-center justify-center bg-button-custom md:hidden sm:hidden">
            <div className="custom-login-box justify-center items-center">
              <h1 className="login-form-text">
                Join The
                <br />
                <span className=" text-black"> AskSophia</span>
                <br />
                Family
              </h1>
              <p className=" text-white login-form-text2 ">
                Your Digital BFF and Lifestyle
                <br />
                Manager is Just One Tap Away!
              </p>
            </div>

            <div className="custom-login-box-2 flex justify-start items-center gap-8">
              <div className="flex flex-row md:flex-col">
                <img src="/images/share_img.png" alt="Benefit_logo" />
              </div>

              <div className=" px-10">
                <ul className="text-left text-white login-list-text">
                  <li className=" list-disc py-2">
                    Extended Two-Week Free Trial
                  </li>
                  <li className=" list-disc py-2">
                    AI-Driven Lifestyle Manager
                  </li>
                  <li className=" list-disc py-2">
                    Trusted by Influencers and Experts
                  </li>
                  <li className=" list-disc py-2">
                    Intuitive Onboarding and User Experience
                  </li>
                  <li className=" list-disc py-2">
                    Continuous Personalization
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
