import React from "react";
import { CiStar } from "react-icons/ci";
import { IoNewspaperSharp } from "react-icons/io5";
import { BsBookmarkStar } from "react-icons/bs";

const SophiaSuggestions = () => {
  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full md:w-1/2 p-6">

      <div className="flex flex-row justify-start items-center gap-4 mb-[50px]">
        <span className="text-[30px] text-[rgb(189,0,255)] mt-2">
          {" "}
          <BsBookmarkStar />
        </span>
        <h1 className="text-[22px] text-black font-bold leading-6 font-sans">
          Sophia’s Suggestions
        </h1>
      </div>

      <div className="flex flex-row justify-start items-center place-content-center gap-4">
        <span className="text-[30px] bg-[#8300D3] rounded-full text-white p-2 mt-2">
          {" "}
          <IoNewspaperSharp />
        </span>
        <h1 className="text-[14px] text-black font-normal font-sans leading-normal">
          You've been looking up a lot of tech news.<br></br>
          <span className="text-[15px] text-black font-bold font-sans leading-normal"> Would you like to turn on daily tech briefings?</span> 
        </h1>

        <div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
            </div>
          </label>
        </div>

      </div>

    </div>
  );
};

export default SophiaSuggestions;
