import React from 'react';
import { FaStar } from "react-icons/fa";
import { BsCalendar3EventFill } from "react-icons/bs";
import { GiSonicShoes } from "react-icons/gi";
import { GiClothes } from "react-icons/gi";
import { FcElectronics } from "react-icons/fc";
import { NavLink } from 'react-router-dom';


const onlineshoppingcard = [
    {
        path: "/details",
        title: "Shoes",
        icon: <GiSonicShoes />,
        items: "2451 photos",
        background: "/images/bg_3.png"

    },
    {
        path: "/details",
        title: "Clothes",
        icon: <GiClothes />,
        items: "2451 photos",
        background: "/images/bg_4.png"

    },

    {
        path: "/details",
        title: "Electronics",
        icon: <FcElectronics />,
        items: "2451 photos",
        background: "/images/bg_1.png"

    },
    {
        path: "/details",
        title: "Shoes",
        icon: <GiClothes />,
        items: "2451 photos",
        background: "/images/bg_3.png"

    },
    {
        path: "/details",
        title: "Electronics",
        icon: <GiClothes />,
        items: "2451 photos",
        background: "/images/bg_4.png"

    },

]

const OnlineShoppingbox = () => {
    return (

        <div className="rounded-xl border border-[#D7D7D7] w-full  p-6" >
            <div className="flex flex-row justify-between items-start gap-4">
                <h1 className="text-[16px] sm:text-[22px] font-bold p-2">Online Shopping</h1>
                <div className="flex justify-center items-center text-black bg-transparent">
                    <NavLink to={"/view-more"}>
                        <h1 className='text-[#A1A1A1] font-semibold text-[14px] hover:text-black'>View More</h1>
                    </NavLink>

                </div>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5  justify-between items-center w-full bg-white rounded-xl gap-4 p-2 sm:p-6">
                {onlineshoppingcard.map((data, i) => {
                    return (

                        <>
                            <div className='flex flex-col  justify-between sm:items-start  items-center gap-4 rounded-lg shadow-xl border-2 '         
                                    style={{
                                    backgroundImage: `url(${data.background})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                            
                                     }}
                                    >

                                <div className='flex flex-col  justify-start items-start w-full'>

                                    <div className='w-full items-center justify-start font-bold text-[50px] p-2 py-10' >
                                        {data.icon}
                                    </div>

                                    <div className='flex flex-row justify-between items-center w-full bg-[#747474b6] py-2 rounded-lg'>

                                        <h1 className='text-white font-semibold text-[18px] lg:text-[20px] leading-8 justify-start items-start p-2'>{data.title}</h1>

                                        <h1 className='text-white font-light text-[14px]  leading-8 justify-start items-start p-2'>{data.items}</h1>

                                    </div>

                                </div>

                            </div >
                        </>

                    );
                })}
            </div >



        </div>

    )
}

export default OnlineShoppingbox