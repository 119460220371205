import React from 'react';
import { FaStar } from "react-icons/fa";
import { BsCalendar3EventFill } from "react-icons/bs";

const recentnews = [
  {
    path: "/details",
    title: "COP28: UAE planned to use climate talks to make oil deals",
    image: "../images/newscard1.png",
    rating: "4.8",
    lesson: "12 Lessons",
    source: "Google News",
  },
  {
    path: "/details",
    title: "2023 NBA bets, lines and stats for Wednesday",
    image: "../images/newscard2.png",
    rating: "4.8",
    lesson: "12 Lessons",
    source: "Google News",
  },

  {
    path: "/details",
    title: "Tyson Foods debuts highly automated $300M poultry plant in VA",
    image: "../images/newscard1.png",
    rating: "4.8",
    lesson: "12 Lessons",
    source: "Google News",
  },

]


const LatestNews = () => {
  return (

    <div className="rounded-xl border border-[#D7D7D7] w-full p-6">
      <div className="flex flex-row justify-between items-start gap-4">
        <h1 className="text-[16px] sm:text-[22px] font-bold p-2">Read Latest  News</h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  justify-between items-center w-full bg-white rounded-xl gap-4 p-2 sm:p-6">
        {recentnews.map((data, i) => {
          return (
            <>
              <div className='flex flex-col  justify-between items-start gap-4 rounded-lg shadow-xl border-2 p-2'>

                <div className='flex flex-col  justify-start items-start'>

                  <img src={data.image} alt='image' className=' h-40 w-full items-center justify-start' />

                  <div className='p-2 flex flex-row gap-4 justify-start items-center '>

                    <div className='flex flex-row justify-center items-center gap-2'>
                      <FaStar className='text-[#8300D3]' />
                      <span className='font-light text-[14px] text-[#263238]  '>{data.rating}</span>
                    </div>

                    <div className='flex flex-row justify-center items-center gap-2'>
                      <BsCalendar3EventFill className='text-[#8300D3]' />
                      <span className='font-light text-[14px] text-[#263238] justify-center items-center '>{data.lesson}</span>
                    </div>

                  </div>

                  <div className='flex flex-row justify-between items-center gap-4 w-full'>

                    <h1 className='text-black font-semibold text-[18px] lg:text-[22px] leading-8 justify-start items-start p-2'>{data.title}</h1>

                  </div>

                </div>

              </div >
            </>

          );
        })}
      </div >

    </div>

  )
}

export default LatestNews

