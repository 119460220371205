import { NavLink ,Link, Navigate} from "react-router-dom";
import { FaBars, FaHome} from "react-icons/fa";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { PiChatCenteredTextThin } from "react-icons/pi";
import { PiPlusCircleThin } from "react-icons/pi";
import { BiCog  } from "react-icons/bi";
import { GoWorkflow } from "react-icons/go";
import { BiSupport } from "react-icons/bi";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {LogoutOutlined,} from '@mui/icons-material';
import SidebarMenu from "./SidebarMenu";
const routes = [
  {
    disabled:false,
    path: "/dashboard",
    name: "Home",
    icon: <FaHome />,
  },
  {
    disabled:false,
    path: "/chat",
    name: "Chat With Sophia",
    icon: <PiChatCenteredTextThin />,
  },
  {
    disabled:false,
    path: "/features",
    name: "Global Features",
    icon: <AiOutlineAppstoreAdd />,
  },
  {
    disabled:false,
    path: "/workflows",
    name: "Workflows",
    icon: <GoWorkflow />,
  },

  {
    disabled:false,
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
  },
  {
    disabled:false,
    path: "/help",
    name: "Help & Support",
    icon: <BiSupport />,
  },


  {
    disabled:false,
    path: "/logout",
    name: "Sign Out",
    icon: <LogoutOutlined />,
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const handleResize =() =>{
      if(window.innerWidth < 768 && isOpen )
      {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize",handleResize);
  
  });

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const navigateTo=(route)=>{
   if(route.disabled){
    return
   }else{
    <Navigate to={route.path}/>
   }
  } 
  return (
    <>
      <div className="main-container shadow-lg border-2 rounded-md">
        <motion.div
          animate={{
            width: isOpen ? "240px" : "70px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <Link
                        to="/dashboard"
                    >
                        <img className="w-[150px]" src="/images/logos/logo_black.png" alt="asksophia" />
                    </Link>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={() =>setIsOpen(!isOpen)} />
            </div>
          </div>
          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
        
                <NavLink
                 to={route.disabled?'#':route.path}
                //  onClick={()=>navigateTo(route)}
                  key={index}
                  // className="link "
                  className={`link ${route.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  // activeClassName="active"
                  activeClassName={route.disabled ? '' : 'active'}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}

           <div className="flex flex-row items-center">
            <span className="text-[50px] cursor-pointer">
            <PiPlusCircleThin />
            </span>
           
            
            </div>

            <div className="flex flex-row items-center">
            <img src="../images/sidebar-cartoon.png" alt="gallery" className="px-1"/>
            <h1 className="text-[#4B5255] px-1 max-w-full">Troy’s Workspace</h1>
            </div>
            

           
          </section>

        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
