
const PromptsHeader = () => {
    return (
        <div className="flex flex-col mb-[20px]">
            <h3 className="text-[28px] leading-[40px] font-semibold mb-4">
                Your Prompts
            </h3>
        </div>
    )
}

export default PromptsHeader