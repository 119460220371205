import React from "react";
import { FaMicrophoneAlt } from "react-icons/fa";

const ChatBottomIcon = () => {
  return (
    <>
      <div class="relative flex justify-end my-[20px]">
        <a
          class="fixed right-8 bottom-4 rounded-full px-4 py-2 text-white z-10"
          href="/chat"
        >
          <img src="../images/chatimg.png" alt="ChatButtonimg" />
        </a>
      </div>

      <div class="relative flex justify-end my-[20px]">
        <span class="fixed right-[140px] bottom-8 rounded-full px-4 py-2 text-white z-10">
          <FaMicrophoneAlt className="bg-gradient-to-b from-[#BD00FF] to-[#8300D3] rounded-full text-white text-[40px] p-2 cursor-pointer" />
        </span>
      </div>
    </>
  );
};

export default ChatBottomIcon;
