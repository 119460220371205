import React, { useState } from "react";
import RecentActivities from "./extra-components/RecentActivities";
import PendingTask from "./extra-components/PendingTask";
import { MdOutlineHistory } from "react-icons/md";
import { Fragment } from 'react';
import { Tab } from '@headlessui/react';


const RecentActivitiesSummary = () => {

  const [selected, Isselected] = useState(false);

  const HandleClick = () => {
    Isselected(!selected);
  }

  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full  p-6 ">
      <div className="flex flex-row justify-start items-center gap-4">
        <span className="text-[30px] text-[rgb(189,0,255)] mt-2">
          {" "}
          <MdOutlineHistory />
        </span>
        <h1 className="text-[22px] text-black font-bold leading-6">Recent Activities Summary</h1>
      </div>

      <div className="m-5">

        <div>
          <Tab.Group>
            <div className="flex flex-row gap-12 p-4 " >
              <Tab.List>
                <Tab as={Fragment} onClick={HandleClick}>
                  {({ selected }) => (

                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected ? ' text-black border-b-2 border-[#8300D3] px-16 py-2' : 'bg-white text-[#A1A1A1] px-16 py-2'
                      }
                    >
                      Last Interactions
                    </button>
                  )}
                </Tab>
                {/* ...  */}
              </Tab.List>

              <Tab.List>
              <Tab as={Fragment} onClick={HandleClick}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected ? ' text-black border-b-2 border-[#8300D3] px-16 py-2' : 'bg-white text-[#A1A1A1] px-16 py-2'
                      }
                    >
                      Pending Task
                    </button>
                  )}
                </Tab>
                {/* ...  */}
              </Tab.List>

            </div>

            <Tab.Panels>
              <Tab.Panel>

                      
              <div><RecentActivities /></div>


              </Tab.Panel>
              
            </Tab.Panels>

            <Tab.Panels>
              <Tab.Panel>

              <div><PendingTask /></div>


              </Tab.Panel>
           
            </Tab.Panels>
          </Tab.Group>


        </div>
     
      </div>
    </div>
  );
};

export default RecentActivitiesSummary;
