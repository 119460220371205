import React from 'react'

const category = [
    {
        title: "All",
        image: "../images/check.png",
    },

    {
        title: "Technology",
        image: "../images/technology.png",
    },
    {
        title: "Politics",
        image: "../images/politics.png",
    },
    {
        title: "Sports",
        image: "../images/sports.png",
    },

]

const Category = () => {


    return (

        <div className='bg-[#E5ECF6] rounded-lg border w-full sm:w-1/2 p-6'>

            <div className='flex flex-row justify-between items-center p-6'>
                <h1 className='text-[16px] sm:text-[24px] leading-6 text-black font-bold items-start justify-start pb-8'>Categories</h1>

            </div>



            {category.map((cat, i) => {
                return (

                    <>
                        <div className='flex flex-row justify-between items-center gap-4'>

                            <div>
                                <img src={cat.image} alt='image' className='h-auto w-auto items-center justify-center' />
                            </div>

                            <div className='flex flex-col justify-between items-start gap-2 w-full'>
                                <h1 className='text-black font-bold text-[16px] leading-6 justify-start items-start '>{cat.title}</h1>
                            </div>

                            <div>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value="" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                    </div>
                                </label>
                            </div>

                        </div>

                        <hr className="h-px my-8 bg-gray-400 border-0 dark:bg-gray-800"></hr>

                    </>

                );
            })}

        </div>
    )
}

export default Category