import React from 'react';
import { GoWorkflow } from "react-icons/go";

const PendingTask = () => {
    return (
        <div className="flex flex-col gap-8 md:flex-row justify-stretch items-center md:gap-14">
    
          <div>
          <span className="text-[30px] text-[rgb(189,0,255)] mt-2">
              {" "}
              <GoWorkflow />
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-[14px] text-[#000000] font-extralight">Thursday, 09:00 AM</p>
            <h1 className="text-[16px] text-[#000000] font-bold">Brief Of Your Latest News</h1>
            <ul className="list-inside list-disc text-[#BD00FF]">
              <li className="text-[14px] font-light"><span className="text-black">News Update 1</span></li>
              <li className="text-[14px] font-light"><span className="text-black">News Update 2</span></li>
            </ul>
          </div>
    
          <div>
            <button className="border text-white text-[14px] md:text-[18px] font-light rounded-xl px-10 py-3 bg-gradient-to-b from-[#BD00FF] to-[#8300D3] w-full  ">Check</button>
          </div>
        </div>
      );
    };
    

export default PendingTask
