import { useState } from "react";
import { Tab } from "@headlessui/react";
import { MdOutlineAccessTime } from "react-icons/md";
import TotalCommandsIssued from "./extra-components/TotalCommandsIssued";
import MostUsedWorkflows from "./extra-components/MostUsedWorkflows";
import TimeSpentChart from "./extra-components/TimeSpentChart";

const MetricSidebarTab = () => {
  const [Active, setActive] = useState("");

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  return (
    <Tab.Group>
      <div className="flex md:justify-center md:items-center justify-start w-full">
        <Tab.List className="flex flex-col  justify-start items-center md:justify-center text-[13px]  md:items-center md:flex-row  gap-2 p-2 rounded-lg md:rounded-full">
          <Tab
            onClick={handleClick}
            className={Active === "1" ? "activetabBtn" : "InactivetabBtn"}
            id={"1"}
          >
            Usage Statistics
          </Tab>
          <Tab
            onClick={handleClick}
            className={Active === "2" ? "activetabBtn" : "InactivetabBtn"}
            id={"2"}
          >
            Behavioral Insights
          </Tab>

          <Tab
            onClick={handleClick}
            className={Active === "3" ? "activetabBtn" : "InactivetabBtn"}
            id={"3"}
          >
            Trend Analysis
          </Tab>

          <Tab
            onClick={handleClick}
            className={Active === "4" ? "activetabBtn" : "InactivetabBtn"}
            id={"4"}
          >
            Privacy & Opt-out
          </Tab>
        </Tab.List>
      </div>

      <Tab.Panels>

        <Tab.Panel className="flex flex-col justify-center items-center gap-8 p-8 w-[350px] sm:w-full">
          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Total Commands Issued</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="TotalCommandsIssued"
                  name="TotalCommandsIssued"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weather update" className=" text-black text-[14px] font-normal">Weekly</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Monthly</option>
                  <option value="Search Assistant" className=" text-black text-[14px] font-normal">Yearly</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
              <TotalCommandsIssued />
            </div>
          </div>

          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Most Used Workflows</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="MostUsedWorkflows"
                  name="MostUsedWorkflows"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                </select>
              </form>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="Most Used Workflows"
                  name="Most Used Workflows"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weekly" className=" text-black text-[14px] font-normal">Weekly</option>
                  <option value="Monthly" className=" text-black text-[14px] font-normal">Monthly</option>
                  <option value="Yearly" className=" text-black text-[14px] font-normal">Yearly</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
              <MostUsedWorkflows />
            </div>
          </div>


          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Time Spent</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="MostUsedWorkflows"
                  name="MostUsedWorkflows"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily News Digest</option>
                </select>
              </form>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="Most Used Workflows"
                  name="Most Used Workflows"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weekly" className=" text-black text-[14px] font-normal">Weekly</option>
                  <option value="Monthly" className=" text-black text-[14px] font-normal">Monthly</option>
                  <option value="Yearly" className=" text-black text-[14px] font-normal">Yearly</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
              <TimeSpentChart/>
            </div>
          </div>
        </Tab.Panel>

        <Tab.Panel className="flex flex-col justify-center items-center gap-8 p-8 w-[350px] sm:w-full">
          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Favorite Topics</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="TotalCommandsIssued"
                  name="TotalCommandsIssued"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weather update" className=" text-black text-[14px] font-normal">Top 7</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Top 15</option>
                  <option value="Search Assistant" className=" text-black text-[14px] font-normal">Top 30</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
            <MostUsedWorkflows />
            </div>
          </div>

          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Interaction Patterns</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="TotalCommandsIssued"
                  name="TotalCommandsIssued"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weather update" className=" text-black text-[14px] font-normal">Weekly</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Monthly</option>
                  <option value="Search Assistant" className=" text-black text-[14px] font-normal">Yearly</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
              <TotalCommandsIssued />
            </div>
          </div>
        </Tab.Panel>

        <Tab.Panel className="flex flex-col justify-center items-center p-8 w-[350px] sm:w-full">

          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Weekly Recap (Command Counts Per Day)</h1>
            </div>
            <div className="flex flex-col">
            <TimeSpentChart/>
            </div>
          </div>

          <div className="flex flex-col border border-[#ffff] text-black w-full  p-4 rounded-xl">
            <div className="flex flex-row justify-between p-4">
              <h1 className="text-[14px] pt-2">Suggested Actions</h1>
              <form className="flex justify-center items-center text-black bg-transparent">
                <select
                  id="TotalCommandsIssued"
                  name="TotalCommandsIssued"
                  className="rounded-xl w-full border-none bg-transparent text-[14px]"
                >
                  <option value="Weather update" className=" text-black text-[14px] font-normal">Daily Tech Briefing</option>
                  <option value="Daily News Digest" className=" text-black text-[14px] font-normal">Daily Tech Briefing</option>
                  <option value="Search Assistant" className=" text-black text-[14px] font-normal">Daily Tech Briefing</option>
                </select>
              </form>
            </div>
            <div className="flex flex-col">
            <MostUsedWorkflows />
            </div>
          </div>
        </Tab.Panel>

        

        <Tab.Panel className="w-[320px] sm:w-full">
          <div className="p-4 flex flex-col gap-2">
            <h1 className="text-[24px] font-medium leading-7 p-4">
              Data Usage Policy
            </h1>
            <p className="text-[16px] font-light  leading-5 p-4">
              We respect your privacy. Learn how we use your data.
            </p>

            <h1 className="text-[16px] font-light leading-5 pl-4">
              Dear User,{" "}
            </h1>
            <div className="flex flex-col gap-2 text-[16px] font-light leading-6 pl-4 text-left">
              At AskSophia, we believe that your data is yours - and you deserve
              the utmost respect when it comes to privacy and use. Our promise
              to you is simple: 
              <p>* Personalization Without Compromise: We only
              use your data to make AskSophia work better for you. Think of it
              as a custom-fit digital suit, tailored just for you, using your
              own preferences and needs as the pattern.</p> 

              <p>* Safety First: Your
              data is stored securely, guarded like the treasure it is. We
              protect it against pirates (hackers) and only you have the map to
              where it's buried.</p>

              <p>* Transparency Always: No secret handshakes or
              hidden agendas here. We’ll always tell you what data we're
              collecting and why. </p>
              
              <p>Remember, you're the captain of this ship. You
              can change your settings or sail away (delete your data) whenever
              you want. </p>
              <p>Your trust is our treasure, and we're committed to
              keeping it safe.</p>
           
              <p>Yours digitally, </p>
              <p>The AskSophia Team</p>
            </div>

            <h1 className="text-[16px] leading-5 font-semibold p-4">
              Link to full policy
            </h1>
            <p className="text-[16px] leading-5 font-light  pl-4 text-black">
              {" "}
              https://www.tlinkexample.com/lorem/consectetur/adipis
            </p>

            <h1 className="text-[20px] leading-5 font-bold p-4">Analytics</h1>
            <p className="text-[16px] leading-5 font-light  pl-4 text-black">
              Do you want to opt-out to analytics?
            </p>

            <h1 className="text-[20px] leading-5 font-bold p-4">Time Saved</h1>
              <p className="text-[16px] leading-5 font-light  pl-4 text-black">
                Average time saved this week
              </p>
            <div className="flex flex-row pl-4 gap-2">
              <MdOutlineAccessTime className="text-[20px] text-[#BD00FF]" />{" "}
              <span className="text-[16px] leading-5 font-bold">2 hours</span>
            </div>
          </div>
        </Tab.Panel>

      </Tab.Panels>
    </Tab.Group>
  );
};

export default MetricSidebarTab;
