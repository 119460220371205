import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiLock } from 'react-icons/bi';
import Background from '../assets/images/final_bg.png';

const WelcomeSurvey = () => {
  const navigate = useNavigate();

  const navigateToOnboradingSurvey = () => {
    // 👇️ navigate to /survey
    navigate('/survey');
  };

  return (
    <div className='flex flex-col justify-center items-center w-full h-screen overflow-hidden' 
    
    style={{
      backgroundImage: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      
      
    }}
    
    >
       
      <div className=" flex justify-center items-center p-1 ">
        <img src="./images/asksophia-logo.png" alt="logo" className="h-full w-full rounded-lg" />
      </div>
      <div className="flex justify-center items-center p-1 ">
        <img src="./images/asksophia1.png" alt="asksophia_logo" className=" h-[120px] w-[120px] sm:h-full sm:w-full rounded-full" />
      </div>
      <div className="flex justify-center items-center">
        <h1 className="font-sans text-[25px] leading-8 font-extrabold p-4">Good Morning <span className="font-bold text-[#8300D3]">Troy!</span></h1>
      </div>

      <div className="flex justify-center items-center">
        <h2 className="font-sans text-[25px] leading-8 font-light p-4 sm:text-[36px] ">Welcome to <span className="font-bold">AskSophia</span></h2>
      </div>

      <div className="flex justify-center items-center">
        <p className="font-sans text-[14px] leading-8 font-light p-1 sm:text-[20px]">Your Digital BFF (Best-Friend  Forever) Awaits</p>
      </div>

      <div className="flex flex-col justify-center items-center">
        <button
          className="font-sans border bg-gradient-to-b from-[#BD00FF] to-[#8300D3] rounded-lg  px-20 py-3 w-auto m-5 text-white hover:-translate-x-1 hover:scale-105 duration-300 sm:px-32 sm:py-3"
          onClick={navigateToOnboradingSurvey}
        >Tap to Begin</button>
      </div>

      <div className="flex justify-center items-center sm:mt-[5px]">
        <BiLock className=" text-[#BD00FF] text-[26px]" />
        <span className="font-sans text-[18px] text-[#393a3a] leading-5 font-semibold p-4 ">Your privacy is our priority.</span>
      </div>



    </div>
  );
};

export default WelcomeSurvey;
