import React from 'react'

const EventsBox = () => {
    return (
        <div className="rounded-xl border border-[#D7D7D7] w-full p-6 bg-[#5A3472] gap-4 justify-center items-center">
        <div className='flex flex-row justify-center items-center gap-4'>
            <div>
                <h1 className='text-[#CD9CF2] text-[20px]  font-bold p-2 '>Sat</h1>
            </div>

            <div>
                <h1 className='text-white text-[20px]  font-bold p-2 '>Dec</h1>
            </div>
        </div>
        <div className='flex justify-center items-center'>
            <h1 className='text-white text-[80px]  md:text-[100px]  font-extrabold p-2 '>2</h1> 
        </div>

        <div className='flex justify-center items-center'>
            <h1 className='text-white text-[14px]  sm:text-[20px]  font-bold p-2'>No events today</h1>
        </div>



    </div>
    )
}

export default EventsBox