import { Helmet } from "react-helmet-async";
import TopComponents from "../components/TopComponents";
import SocialMediaRecap from "../components/SocialMediaRecap";
import RecentActivitiesSummary from "../components/RecentActivitiesSummary";
import QuickActions from "../components/QuickActions";
import UserSettingsPreferences from "../components/UserSettingsPreferences";
import { CiSearch } from "react-icons/ci";
import OpenMood from "../components/extra-components/OpenMood";
import ChatBottomIcon from "../components/extra-components/ChatBottomIcon";
import PaymentHistory from "../components/PaymentHistory";
import OnlineShoppingbox from "../components/OnlineShoppingbox";

import LatestNews from "../components/LatestNews";
import MostWorkflowsUsed from "../components/MostWorkflowsUsed";
import SophiaSuggestions from "../components/SophiaSuggestions";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard | AskSophia</title>
      </Helmet>

      <section className="p-4 sm:p-6">

        { /* ----search and open moood ----
            <section className="flex-col flex sm:flex-row sm:justify-around px-[30px] py-8 justify-center items-center gap-4 sm:gap-20 md:gap-20 lg:gap-40 ">
          <div className="flex flex-row w-full justify-center items-center lg:w-1/2 shadow-lg border-[2px] rounded-xl">
            <CiSearch className="text-[20px]" />
            <input
              type="search"
              placeholder="Search anything you like"
              className=" focus:border-none px-10 py-3 sm:py-[15px] border-none  w-full xl:w-1/2 "
            />
          </div>

          <div className="flex flex-row justify-center items-center w-full  lg:w-1/2">
            <OpenMood />
          </div>
        </section>
        
        
        
        */

        }
        <section className="flex flex-col sm:flex-row w-full gap-4 mb-2 mt-2">        

          <TopComponents />
          <QuickActions />
        </section>

        <section className="flex flex-col sm:flex-row w-full gap-4 mb-2 mt-2">
        <RecentActivitiesSummary />
          <MostWorkflowsUsed />
        </section>

       {
        /*
        <section className="flex flex-col sm:flex-row w-full gap-4 mb-2 mt-2">
          <PaymentHistory />
        </section>
        
        */
       } 

        <section className="flex flex-col sm:flex-row w-full gap-4 mb-2 mt-2">
      
          <SocialMediaRecap />
          <SophiaSuggestions />
        </section>
        

        <section className="flex flex-col justify-center items-center gap-2 ">

          <LatestNews />

        </section>

        <section className="flex flex-col sm:flex-row w-full gap-4 mb-2 mt-2">
          <OnlineShoppingbox />
        </section>

        <ChatBottomIcon />

      </section>



    </>
  );
};

export default Dashboard;
