import React from "react";
import { GoWorkflow } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { FiBarChart2 } from "react-icons/fi";
import { MdDoneAll } from "react-icons/md";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { BsBagCheck } from "react-icons/bs";
import { TbSocial } from "react-icons/tb";
import { NavLink } from "react-router-dom";


const Workflows = [
  {
    title: "You checked the weather",
    time: "Today, 09:00 AM",
    link: "workflows",
    icon: <TiWeatherPartlySunny />,
  },
  {
    title: "Brief Of Your Latest News",
    time: "Today, 09:00 AM",
    link: "workflows/weather-update",
    icon: <HiOutlineNewspaper />,
  },

  {
    title: "Online Shopping",
    time: "Today, 09:00 AM",
    link: "workflows/online-shopping",
    icon: <BsBagCheck />,
  },
  {
    title: "Social Media Recap",
    time: "Today, 09:00 AM",
    link: "workflows/social-media-recap",
    icon: <TbSocial />,
  },

]


const RecentActivities = () => {
  return (
    <div className="flex flex-col gap-8 lg:flex-row justify-stretch items-center lg:gap-14 ">

      <div className="mt-2 mb-2 w-full">

        {Workflows.map((wflows, i) => {
          return (

            <>
              <div className='flex flex-row justify-between items-center gap-4 shadow-md p-2'>

                <div className='flex items-center justify-center text-[#8300D38A] text-[30px] rounded-full  bg-[#F5E7FD] p-4 shadow-md'>
                  {wflows.icon}
                </div>

                <div className='flex flex-col justify-between items-start gap-2 w-full'>
                  
                  <p className="text-[#A1A1A1] text-light text-[12px]">{wflows.time}</p>

                  <h1 className='text-[#4B5255] font-normal text-[14px] leading-6 justify-start items-start hover:text-[#111111]'>
                    <NavLink to={`/${wflows.link}`}>
                      {wflows.title}
                    </NavLink>
                  </h1>

                </div>

              </div>


            </>

          );
        })}

      </div>


    </div>
  );
};

export default RecentActivities;
