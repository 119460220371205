import React from "react";
import { FaMicrophoneAlt } from "react-icons/fa";
import { SlEnergy } from "react-icons/sl";

const QuickActions = () => {
  return (
    <div className="rounded-xl border border-[#ffffff] w-full  p-6 bg-white gap-4">

      <div className="flex flex-row justify-start items-center gap-4">
        < SlEnergy className="text-[#A201EB] font-extrabold text-[40px]"/>
        <h1 className="text-[22px] font-bold p-2">Quick Actions</h1>
      </div>

      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col justify-around gap-4 sm:w-[70%]">
          <label for="workflow" className="text-[20px] font-medium p-4">Choose a workflow</label>
          <form className="flex justify-around items-center gap-4 p-4">
            <select id="workflow" name="workflow" className="focus:outline-none rounded-xl w-full border-none">
              <option value="Weather update">Weather Update</option>
              <option value="Daily News Digest">Daily News Digest</option>
              <option value="Search Assistant">Search Assistant</option>
              <option value="Social Media Recap">Social Media Recap</option>
            </select>
            <input type="submit" value="Start" className="bg-gradient-to-b from-[#BD00FF] to-[#8300D3] text-white w-1/2 py-2 px-10 rounded-xl" />
          </form>
        </div>
      </div>

      <div className="flex flex-row gap-20 p-4  justify-start items-center w-full">
        <div>
          <h1 className="text-[14px] sm:text-[16px] text-black font-bold leading-6">
            Voice Command Activation
          </h1>
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          <span><FaMicrophoneAlt className="bg-gradient-to-b from-[#BD00FF] to-[#8300D3] rounded-full text-white text-[40px] p-2 cursor-pointer" /></span>
          <h2>Press To Speak</h2>
        </div>

      </div>

    </div>
  );
};

export default QuickActions;
