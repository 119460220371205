import { useEffect, useState } from "react";
import axios from "axios";
const AiChat = () => {
  const [chat, setChat] = useState([]);
  console.log(chat);
  const [chatId, setChatId] = useState("");
  const [message, setMessage] = useState("");
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    const messages = document.getElementById("messages");
    messages.scrollTop = messages.scrollHeight;
  }, []);
  const getNameResponse = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}chatSophia/getResponseWithName`,
      { username: "troy", userId: "649d25d18a06f6f64a2e123e" }
    );
    setChat([{ sender: "sophia", message: result.data.result.message }]);
    console.log(result.data.chat._id);
    const question = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}chatSophia/getQuestions`
    );
    console.log(question);
    setQuestions(question.data.result.message);
    setChatId(result.data.chat._id);
    // console.log(chat);
  };
  const sendMessageToSophia = async () => {
    let msg = message;
    setMessage("");
    setChat((prevChat) => [...prevChat, { sender: "user", message: msg }]);
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}chatSophia/getPromptResponse`,
      { prompt: msg, chatId: chatId }
    );
    setChat((prevChat) => [
      ...prevChat,
      { sender: "sophia", message: result.data.result?.message },
    ]);
  };
  const sendQuestionToSophia = async (question) => {
    console.log(question);
    setChat((prevChat) => [...prevChat, { sender: "user", message: question }]);

    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}chatSophia/getPromptResponse`,
      {
        prompt: question,
        chatId: chatId,
      }
    );
    setChat((prevChat) => [
      ...prevChat,
      { sender: "sophia", message: result.data.result.message },
    ]);
  };
  useEffect(() => {
    getNameResponse();
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageToSophia();
    }
  };
  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKeyPress);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [message]); 
  return (
    <section>
      <div className="">
        <div className="flex-1 p-2 sm:p-6 justify-between bg-gray-100 flex flex-col ">
          <div>
            {/* topbar */}
            <div className="flex sm:items-center justify-between  py-3 border-b-2 border-gray-200">
              <div className="flex justify-between ">
                {/* User */}
                <div className="relative flex items-center space-x-4">
                  <div className="relative">
                    <span className="absolute text-green-500 right-0 bottom-0">
                      <svg width="20" height="20">
                        <circle
                          cx="8"
                          cy="8"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 sm:w-16 h-10 sm:h-16 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-700 mr-3">Troy</span>
                    </div>
                    <span className="text-lg text-gray-600">Developer</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button className="border-2 bg-red-100 border-red-100 text-red-500 px-6 rounded-lg py-1 ">
                  chat
                </button>
                <button className="border-2 border-gray-800 px-6 rounded-lg py-1">
                  Regenrate
                </button>
                {/* Add other buttons */}
              </div>
            </div>

            {/* messages */}
            <div
              id="messages"
              className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
            >
              {/* Chat messages start here */}
              <div className="chat-message">
                {chat?.map((item) => {
                  return item.sender == "sophia" ? (
                    <div className="flex items-end mb-4">
                      <div className="flex flex-col space-y-2 text-xs w-full mx-2 order-2 items-start">
                        <div className="py-2 px-4 rounded-lg rounded-bl-none bg-gray-300 text-gray-600">
                          {/* <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600"
                         style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                          > */}
                             <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{item.message }</pre>
                          {/* </span> */}
                        </div>
                      </div>
                      <img
                        src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                        alt="My profile"
                        className="w-6 h-6 rounded-full order-1"
                      />
                    </div>
                  ) : (
                    <div className="chat-message mb-4">
                      <div className="flex items-end justify-end">
                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                          <div>
                            <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-violet-700 text-white">
                              {item.message}
                            </span>
                          </div>
                        </div>
                        <img
                          src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                          alt="My profile"
                          className="w-6 h-6 rounded-full order-2"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* input chat section */}
          <div className=" items-center px-4 sm:mb-0">
            <div className="flex border-gray-300 border rounded-2xl px-4 py-2  ">
              <input
                type="text"
                placeholder="Write your message!"
                value={message}
                // onKeyUp={(e)=>{handleKeyPress(e)}}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="w-full focus:outline-none bg-gray-100 focus:placeholder-gray-400 text-gray-600 placeholder-gray-600   rounded-md py-3"
              />
              {/* Add other buttons */}
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              >
                {/* Paperclip (Document Selection) Icon SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 11a1 1 0 00-1 1v8a2 2 0 002 2h14a2 2 0 002-2v-8a1 1 0 00-2 0v8a1 1 0 01-1 1H6a1 1 0 01-1-1v-8a1 1 0 00-1-1z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 3a3 3 0 013 3 3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3 3M9 6a3 3 0 013 3 3 3 0 013 3M9 12a3 3 0 013 3 3 3 0 013 3M9 18a3 3 0 013 3 3 3 0 013 3"
                  ></path>
                </svg>
              </button>

              <button
                type="button"
                className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              >
                {/* Link Icon SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 5a3 3 0 01-3 3M4 10h7a4 4 0 014 4M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </button>

              {/* voice icon */}
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  ></path>
                </svg>
                <span className=" flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                      ></path>
                    </svg>
                  </button>
                </span>
              </button>

              <button
                type="button"
                onClick={() => {
                  sendMessageToSophia();
                }}
                className="inline-flex items-center justify-center rounded-lg px-4  transition duration-500 ease-in-out text-white bg-violet-700 hover:bg-violet-600 focus:outline-none"
              >
                {/* Next Arrow Icon SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-6 w-6 ml-2 transform rotate-90"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </div>
            <div className="flex mt-4 justify-between">
              {questions.map((item, index) => {
                return (
                  <button
                    className="py-2 px-4 border-2 border-gray-300 rounded-2xl "
                    onClick={() => {
                      sendQuestionToSophia(item);
                    }}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AiChat;
