import {
  EmailOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../App";
import { isFilledSurvey, login, loginGoogleUser } from "../api/userService";

import { Helmet } from "react-helmet-async";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);
  const [visible, setVisible] = useState(false);

  const from = location.state?.from?.pathname || "/chat";

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  useEffect(() => {
    if (state.isError) {
      toast.error(state.message);
    }
    if (state.user || state.isSuccess) {
      console.log(state.user.status);
      if(state.user.status){
        navigate(from, { replace: true });
      }else{
        navigate('/survey')
      }
     
    }

    dispatch({ type: "RESET" });
  }, [
    state.isError,
    state.isLoading,
    state.isSuccess,
    state.user,
    state.message,
    navigate,
    dispatch,
  ]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(formData, dispatch);
  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => loginGoogleUser(response.credential, dispatch),
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        theme: "",
        text: "",
        shape: "",
      });
    }
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>AskSophia | Sign in</title>
      </Helmet>
      <div className="overflow-hidden h-screen">
        <div class=" flex flex-col lg:flex-row ">
          <div className="w-full flex items-center justify-center h-screen bg-white">
            <div className="rounded-t-lg py-8 w-full flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/asksophia-logo.png"
                    alt="asksphia logo"
                    className="mb-4 w-[180px]"
                  />
                </div>
              </div>

              <div className=" w-fit items-center justify-center">
              <div className="py-2">
                <h3 className=" text-black text-left text-[30px] leading-[40px] font-bold mb-4">
                Log In
              </h3>
                </div>
                <form className="signUp" onSubmit={handleSubmit}>
                  <div className="mb-4 relative">
                    <label htmlFor="email">
                      <h1 className="py-3">Email</h1>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      className="text-[14px] h-[55px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Email"
                      required
                    />
                    <span className="absolute right-4 text-gray-400 justify-center items-center h-5 w-5">
                      <EmailOutlined />
                    </span>
                  </div>
                  <div className="mb-4 relative">
                    <label htmlFor="Password">
                      <h1 className="py-3">Password</h1>
                    </label>
                    <input
                      type={`${visible ? "text" : "password"}`}
                      value={password}
                      onChange={handleChange}
                      id="password"
                      name="password"
                      className="text-[14px] h-[54px] border border-gray-100 rounded-[10px] py-2 px-4 outline-0 w-full"
                      placeholder="Password"
                      required
                    />
                    <span
                      onClick={() => setVisible(!visible)}
                      className="cursor-pointer absolute right-4 text-gray-400 justify-center items-center  h-5 w-5"
                    >
                      {visible ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </span>
                  </div>
                  <div className="flex justify-end">
                    <Link
                      to="/forgot-password"
                      className="text-[14px] text-black font-medium"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="flex mt-[27px] ">
                    <button
                      className="w-full text-xl bg-button-custom rounded-md px-4 py-3 text-white"
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                </form>
                <div className="flex lg:hidden mt-[27px] ">
                  <Link
                    to="/sign-up"
                    className="w-full text-[18px] text-center rounded-md px-4 py-2 text-white bg-button-custom"
                  >
                    Sign Up
                  </Link>
                </div>
                <div className="relative my-4">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-[#E4E6E8]"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or Login with
                    </span>
                  </div>
                </div>
                <div className="flex flex-col mb-4 gap-2">
                  <div className="mt-6 flex flex-col justify-center space-x-4 space-x-reverse gap-4 lg:flex-row">
                    <div className="flex items-center w-full justify-center custom-login-auth-button">
                      <button className=" flex justify-center items-center fa-2xl">
                        <BiLogoFacebook />
                      </button>
                    </div>
                    <div className="flex items-center  w-full justify-center custom-login-auth-button">
                      <button
                        id="loginDiv"
                        className=" flex justify-center items-center fa-2xl"
                      >
                        <FcGoogle />
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" lg:flex justify-center items-center p-8 text-center md:hidden sm:hidden">
                  <h3 className=" text-black">
                    Don’t have an account?
                    <Link to="/sign-up">
                      <span className=" text-lg text-[#BD00FF] font-semibold">
                        {" "}
                        Sign Up
                      </span>
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:flex flex-col items-center justify-center bg-button-custom md:hidden sm:hidden">
            <div className="custom-login-box justify-center items-center">
              <h1 className="login-form-text">
                Join The
                <br />
                <span className=" text-black"> AskSophia</span>
                <br />
                Family
              </h1>
              <p className=" text-white login-form-text2 ">
                Your Digital BFF and Lifestyle
                <br />
                Manager is Just One Tap Away!
              </p>
            </div>

            <div className="custom-login-box-2 flex justify-start items-center gap-8">
              <div className="flex flex-row md:flex-col">
                <img src="/images/share_img.png" alt="Benefit_logo" />
              </div>

              <div className=" px-10">
                <ul className="text-left text-white login-list-text">
                  <li className=" list-disc py-2">
                    Extended Two-Week Free Trial
                  </li>
                  <li className=" list-disc py-2">
                    AI-Driven Lifestyle Manager
                  </li>
                  <li className=" list-disc py-2">
                    Trusted by Influencers and Experts
                  </li>
                  <li className=" list-disc py-2">
                    Intuitive Onboarding and User Experience
                  </li>
                  <li className=" list-disc py-2">
                    Continuous Personalization
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
};
export default Login;
