import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./survey.css";
import { json } from "./json";
import axios from "axios";
import { UserContext } from "../App";
import Background from '../assets/images/final_bg.png';

function OnbordingSurvey() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);
  const [survey] = React.useState(new Model(json));
  const [pageNo, setPageNo] = React.useState(survey.currentPageNo);
  console.log(pageNo);
  const [isRunning, setIsRunning] = React.useState(true);
  const [userResponses, setUserResponses] = React.useState(null);
  const [imageFile, setimageFile] = React.useState(null);
  console.log(userResponses);
  survey.onCurrentPageChanged.add((_, options) => {
    setPageNo(options.newCurrentPage.visibleIndex);
  });
  survey.onValueChanged.add((sender, options) => {
    console.log(options);
    setUserResponses({ ...userResponses, [options.name]: options.value });
  });
  survey.onStarted.add(() => {
    console.log("i am add");
    setIsRunning(true);
  });
  survey.onComplete.add(() => {
    console.log("ss");
    setIsRunning(false);
  });
  const prevPage = () => {
    survey.prevPage();
  };
  const nextPage = () => {
    survey.nextPage();
  };
  const endSurvey = async () => {
    console.log("sssss");
    survey.completeLastPage();
  };
  const jumpToPage = (pageNo) => {
    if (survey.currentPage.validate()) {
      setPageNo(pageNo);
    }
  };

  const renderButton = (text, func, canRender) => {
    if (!canRender) return undefined;
    return (
      <button className="navigation-button" onClick={func}>
        {text}
      </button>
    );
  };

  function mapToFrontendToFrontend() {
    const backendData = [];

    if (userResponses?.category?.length) {
      for (const category of userResponses.category) {
        let categoryObject = {
          category: category,
          questions: [],
        };
        // Assuming you have three questions for each category
        const dailyNews = [
          {
            question: "Morning Briefing",
            status: false,
          },
          {
            question: "Lunch Break Update",
            status: false,
          },
          {
            question: "Evening Recap",
            status: false,
          },
        ];
        const weatherUpdate = [
          {
            question: "Start of Day Forecast",
            status: false,
          },
          {
            question: "Pre-Commute Check",
            status: false,
          },
          {
            question: "Travel Planning Weather",
            status: false,
          },
        ];
        const searchAssistant = [
          {
            question: "Quick Fact Check",
            status: false,
          },
          {
            question: "Deep Dive Research",
            status: false,
          },
          {
            question: "Shopping Comparison",
            status: false,
          },
        ];
        const socialMediaRecap = [
          {
            question: "Morning Social Summary",
            status: false,
          },
          {
            question: "Midday Check-In",
            status: false,
          },
          {
            question: "Evening Wrap-Up",
            status: false,
          },
        ];
        const onlineShopping = [
          {
            question: "Daily Deals Alert",
            status: false,
          },
          {
            question: "Price Drop Notifications",
            status: false,
          },
          {
            question: "New Arrivals Showcase",
            status: false,
          },
        ];
        const recipeFinder = [
          {
            question: "Meal Prep Ideas",
            status: false,
          },
          {
            question: "Quick Dinner Recipes",
            status: false,
          },
          {
            question: "Weekend Cooking Inspiration",
            status: false,
          },
        ];
        const fitnessRoutine = [
          {
            question: "Morning Workout Kickstart",
            status: false,
          },
          {
            question: "Midday Movement Break",
            status: false,
          },
          {
            question: "Evening Relaxation Yoga",
            status: false,
          },
        ];

        const localEvents = [
          {
            question: "Upcoming Weekend Events",
            status: false,
          },
          {
            question: "Cultural Happenings This Month",
            status: false,
          },
          {
            question: "Nightlife Spotter",
            status: false,
          },
        ];

        const movieRecommendations = [
          {
            question: "Movie Night Selections",
            status: false,
          },
          {
            question: "New Releases Highlight",
            status: false,
          },
          {
            question: "Critically Acclaimed Picks",
            status: false,
          },
        ];

        const bookRecommendations = [
          {
            question: "Weekly Bestsellers",
            status: false,
          },
          {
            question: "Monthly Book Club Picks",
            status: false,
          },
          {
            question: "Personalized Genre Suggestions",
            status: false,
          },
        ];

        const podcastDiscovery = [
          {
            question: "Commute Companion",
            status: false,
          },
          {
            question: "Workday Podcasts",
            status: false,
          },
          {
            question: "Evening Wind-Down",
            status: false,
          },
        ];

        const musicDiscovery = [
          {
            question: "Morning Motivation Mix",
            status: false,
          },
          {
            question: "Work Focus Tunes",
            status: false,
          },
          {
            question: "Dinner Party Playlist",
            status: false,
          },
        ];

        const gameSuggestions = [
          {
            question: "Casual Gaming Break",
            status: false,
          },
          {
            question: "Latest Game Drops",
            status: false,
          },
          {
            question: "Multiplayer Match-ups",
            status: false,
          },
        ];

        const toDoList = [
          {
            question: "Daily Task Organizer",
            status: false,
          },
          {
            question: "Weekly Planning Assistant",
            status: false,
          },
          {
            question: "Project Milestone Tracker",
            status: false,
          },
        ];

        const languageLearning = [
          {
            question: "Daily Language Nugget",
            status: false,
          },
          {
            question: "Weekly Conversation Challenge",
            status: false,
          },
          {
            question: "Cultural Immersion Tip",
            status: false,
          },
        ];

        const travelPlanner = [
          {
            question: "Weekend Getaway Ideas",
            status: false,
          },
          {
            question: "Upcoming Trip Checklist",
            status: false,
          },
          {
            question: "Seasonal Travel Spots",
            status: false,
          },
        ];

        const factOfTheDay = [
          {
            question: "Daily Trivia Teaser",
            status: false,
          },
          {
            question: "Historical Fact of the Day",
            status: false,
          },
          {
            question: "Science Factoid",
            status: false,
          },
        ];

        const moneySavingTips = [
          {
            question: "Daily Budget Tip",
            status: false,
          },
          {
            question: "Weekly Finance Checkup",
            status: false,
          },
          {
            question: "Monthly Savings Goal Tracker",
            status: false,
          },
        ];

        const relaxationGuide = [
          {
            question: "Morning Mindfulness Moment",
            status: false,
          },
          {
            question: "Afternoon Breather",
            status: false,
          },
          {
            question: "Bedtime Unwind",
            status: false,
          },
        ];

        const techNews = [
          {
            question: "Daily Tech Brief",
            status: false,
          },
          {
            question: "Weekly Innovation Roundup",
            status: false,
          },
          {
            question: "Monthly Tech Deep Dive",
            status: false,
          },
        ];
        let questionArray = [];
        if (category === "Daily News Digest") {
          questionArray = dailyNews;
        } else if (category === "Weather Update") {
          questionArray = weatherUpdate;
          // Add more conditions for other categories if needed
        } else if (category === "Search Assistant") {
          questionArray = searchAssistant;
        } else if (category === "Search Assistant") {
          questionArray = socialMediaRecap;
        } else if (category === "Online Shopping") {
          questionArray = onlineShopping;
        } else if (category === "Recipe Finder") {
          questionArray = recipeFinder;
        } else if (category === "Fitness Routine") {
          questionArray = fitnessRoutine;
        } else if (category === "Local Events") {
          questionArray = localEvents;
        } else if (category === "Movie Recommendations") {
          questionArray = movieRecommendations;
        } else if (category === "Book Recommendations") {
          questionArray = bookRecommendations;
        } else if (category === "Podcast Discovery") {
          questionArray = podcastDiscovery;
        } else if (category === "Music Discovery") {
          questionArray = musicDiscovery;
        } else if (category === "Game Suggestions") {
          questionArray = gameSuggestions;
        } else if (category === "To-Do List") {
          questionArray = toDoList;
        } else if (category === "Language Learning") {
          questionArray = languageLearning;
        } else if (category === "Travel Planner") {
          questionArray = recipeFinder;
        } else if (category === "Fact of the Day") {
          questionArray = factOfTheDay;
        } else if (category === "Money-Saving Tips") {
          questionArray = moneySavingTips;
        } else if (category === "Relaxation Guide") {
          questionArray = relaxationGuide;
        } else if (category === "Tech News") {
          questionArray = techNews;
        }
        categoryObject.questions = questionArray
        if (userResponses[category] && userResponses[category].length) {
         
          const questionObject = questionArray.map(
            (availableQuestion, index) => {
              const existingQuestion = userResponses[category].find(
                (q) => q === availableQuestion.question
              );
              if (existingQuestion) {
                return {
                  q: availableQuestion.question,
                  status: true,
                };
              } else {
                return {
                  q: availableQuestion.question,
                  status: false,
                };
              }
            }
          );
          categoryObject.questions = questionObject;
        }

        backendData.push(categoryObject);
      }
    }

    return backendData;
  }

  function formatlastStepData() {
    let importData = {
      password: false,
      searchHistory: false,
      extensions: false,
      bookMarks: false,
      source:
        userResponses?.sourceImports && userResponses.sourceImports.length
          ? userResponses.sourceImports[0]
          : null,
      file:imageFile
    };
    if (userResponses?.imports?.length) {
      for (const imp of userResponses.imports) {
        if (imp === "Passwords") {
          importData.password = true;
        } else if (imp === "Search History") {
          importData.searchHistory = true;
          // Add more conditions for other categories if needed
        } else if (imp === "Extensions") {
          importData.extensions = true;
        } else if (imp === "Bookmarks") {
          importData.bookMarks = true;
        }
      }
    }

    return importData;
  }

  const handleSubmit = async () => {
    let userId=state.user._id
    if (pageNo == 8) {
      const privacyData = {
        userId,
        cookies: userResponses?.cookies || false,
        searchHistory: userResponses?.searchHistory || false,
        locationServices: userResponses?.locationServices || false,
        personalizedAds: userResponses?.personalizedAds || false,
        dataSharing: userResponses?.dataSharing || false,
        thirdPartyTracker: userResponses?.thirdPartyTracker || false,
        cameraAndMicrophone: userResponses?.cameraAndMicrophone || false,
        notification: userResponses?.notification || false,
        autoLogin: userResponses?.autoLogin || false,
        biometricVerify: userResponses?.biometricVerify || false,
      };
      const customizationData = {
        userId,
        darkMode: userResponses?.darkMode || false,
        fontSize: userResponses?.fontSize,
        layout: userResponses?.layout,
        notificationStyle: userResponses?.notificationStyle,
        colorScheme: userResponses?.colorScheme,
        soundEffect: userResponses?.soundEffect || false,
        language: userResponses?.language || "english",
        textToSpeech: userResponses?.textToSpeech || false,
        showPreviews: userResponses?.showPreviews || false,
        accessibility: userResponses?.accessibility || false,
      };
      const browseProfile = {
        userId,
        newsAndResearch: userResponses?.newsAndResearch || false,
        socialMediaEntertainment:
          userResponses?.socialMediaEntertainment || false,
        shoppingService: userResponses?.shoppingService || false,
      };
      const newsFeed = {
        userId,
        worldFeeds: userResponses?.worldFeeds || false,
        tech: userResponses?.tech || false,
        entertainment: userResponses?.entertainment || false,
        sports: userResponses?.sports || false,
        science: userResponses?.science || false,
        health: userResponses?.health || false,
      };
      const useCases = mapToFrontendToFrontend();
      let useCasesData = {
        userId,
        useCases,
      };
      const impData = formatlastStepData();
      const formData=new FormData()
      formData.append('userId',userId)
      formData.append('bookMarks',impData?.bookMarks||false)
      formData.append('passwords',impData?.password||false)
      formData.append('searchHistory',impData?.searchHistory||false)
      formData.append('extensions',impData?.extensions||false)
      formData.append('source',impData?.source)
      formData.append('file',impData?.file)
      const privacyApiCall = `${process.env.REACT_APP_BACKEND_URL}privacy/createPrivacy`;
      const customizationAPi = `${process.env.REACT_APP_BACKEND_URL}customization/createCustomization`;
      const browseApiCall = `${process.env.REACT_APP_BACKEND_URL}profile/createProfile`;
      const newsApiCall = `${process.env.REACT_APP_BACKEND_URL}profile/createnewsFeedCategories`;
      const useCaseApiCall = `${process.env.REACT_APP_BACKEND_URL}workflow/recordResponseByCategory`;
      const impDataApiCall=`${process.env.REACT_APP_BACKEND_URL}userData/saveUserData`
      const requests = [
        axios.post(privacyApiCall, privacyData),
        axios.post(customizationAPi, customizationData),
        axios.post(browseApiCall, browseProfile),
        axios.post(newsApiCall, newsFeed),
        axios.post(useCaseApiCall, useCasesData),
        axios.post(impDataApiCall,formData),
      ];
      try {
        const [
          privacyResult,
          customizationResult,
          browseResult,
          newsResult,
          useCase,
          userData
        ] = await Promise.all(requests);
        let updateStatus=state.user
        updateStatus.status=true;
        console.log(updateStatus);
        localStorage.setItem("user__asksophia", JSON.stringify(updateStatus));
        navigate("/complete-survey");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("step no complete");
    }
  };
  survey.onUploadFiles.add((_, options) => {
    console.log(options.files);
    setimageFile(options.files[0]);
    const formData = new FormData();
    options.files.forEach((file) => {
      formData.append(file.name, file);
    });

    fetch("https://api.surveyjs.io/private/Surveys/uploadTempFiles", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        options.callback(
          "success",
          options.files.map((file) => {
            return {
              file: file,
              content:
                "https://api.surveyjs.io/private/Surveys/getTempFile?name=" +
                data[file.name],
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  });

  return (
    <div className="flex flex-col justify-center items-center w-full "
   
    style={{
      backgroundImage: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      
    }}
    
    
    >
      <div className="py-2">
        <img src="./images/asksophia-logo.png" alt="logo" />
      </div>
      <div className="flex flex-col justify-center items-center rounded-xl shadow-lg border-[2px] m-4 sm:m-0">
        <Survey currentPageNo={pageNo} model={survey} />
     
       {
        pageNo==8 ? <div
        style={{ cursor: "pointer" }}
        onClick={handleSubmit}
        className="border bg-gradient-to-b from-[#BD00FF] to-[#8300D3] rounded-lg px-10 py-3 text-white hover:text-black m-3"
      >
        <h1 className="font-sans">Confirm</h1>
      </div>:null
       }
      
        <div className="p-1 bg-[#F3F3F3] w-full text-center text-[#757474] decoration-solid hover:text-[#BD00FF]">
          <Link to="/chat">
            <h1 className="font-sans">Skip for now</h1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OnbordingSurvey;
