import { useContext, useEffect, useState } from "react";
import {  Navigate,  } from "react-router-dom";
import { UserContext } from "../../../App";
import { LuShoppingCart } from "react-icons/lu";
import { getOneUser } from "../../../api/userService";
import { IoIosNotificationsOutline } from "react-icons/io";
import { GoFileDirectory } from "react-icons/go";
import { SlRefresh } from "react-icons/sl";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";

const Header = ({ onLogout }) => {
  const { state } = useContext(UserContext);
  const [profile, setProfile] = useState(null);

  const getUserprofile = async () => {
    const prof = await getOneUser(state.user._id);
    setProfile(prof);
  };

  useEffect(() => {
    getUserprofile();
  }, []);

  if (!state.user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="w-full border-b-1 border-[#858585] py-1 mb-3">

      <div className="flex  flex-col-reverse md:flex-row justify-between w-full">
        <div className=" flex-col p-[2px] gap-0 md:flex md:flex-row justify-start items-start sm:gap-6 sm:p-2">
          <div className="flex flex-col">
            <h2 className="text-[14px] leading-4 md:text[20px] text-[#8300D3] md:leading-[25px] font-bold place-content-start">
              Welcome back, Troy!
            </h2>
            <h1 className="text-[14px] leading-4 md:text-[22px] md:leading-[29px] font-bold place-content-start">
              How can I assist you today?
            </h1>
          </div>
        </div>

        <div className="flex flex-row gap-2 justify-between items-center  sm:gap-4 md:justify-center md:items-center">
          <div className="flex flex-row justify-start md:items-start items-center">
            <span className="border border-[#858585] text-[#858585] rounded-full text-[12px] md:text-[20px] p-2 m-1 cursor-pointer">
              <CiSearch />
            </span>

            <span className=" border border-[#858585] text-[#858585] rounded-full text-[12px]  md:text-[20px] p-2 m-1 cursor-pointer">
              <LuShoppingCart />
            </span>
            
            <span className="border border-[#858585] text-[#858585] rounded-full text-[12px]  md:text-[20px] p-2 m-1  cursor-pointer">
              <SlRefresh />
            </span>
          </div>

          <div className="flex flex-row  items-center justify-center gap-4">
              <span className=" text-[20px] md:text-[25px] text-[#8300D3] cursor-pointer">
                <IoIosNotificationsOutline />
              </span>
        
            <div className="flex flex-row gap-2">
              <img
                className="peer h-8 w-8 cursor-pointer md:w-14 md:h-14 rounded-xl object-cover"
                src={`${
                  profile && profile.img_url
                    ? profile.img_url
                    : "/images/profile_avatar.png"
                }`}
                alt="user"
              />
              <div className="flex flex-col justify-center items-center gap-1">

                <div className="flex flex-row justify-center items-center">
                <h1 className="text-[12px]  sm:text-[14px] font-bold">Musfiq</h1><MdKeyboardArrowDown className="cursor-pointer justify-center items-center" /> 
                </div>
              
                 <h3 className="text-[10px] sm:text-[12px] font-normal">Admin</h3> 
              </div>
              
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
export default Header;
